@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap";
@media screen and (-ms-high-contrast: active) {
  svg {
    fill: buttontext;
  }
}

html, .cds-theme-zone-white {
  --cds-ai-aura-end: #fff0;
  --cds-ai-aura-hover-background: #edf5ff;
  --cds-ai-aura-hover-end: #fff0;
  --cds-ai-aura-hover-start: #4589ff52;
  --cds-ai-aura-start: #4589ff1a;
  --cds-ai-aura-start-sm: #4589ff29;
  --cds-ai-border-end: #78a9ff;
  --cds-ai-border-start: #a6c8ffa3;
  --cds-ai-border-strong: #4589ff;
  --cds-ai-drop-shadow: #0f62fe1a;
  --cds-ai-inner-shadow: #4589ff1a;
  --cds-ai-overlay: #00114180;
  --cds-ai-popover-background: #fff;
  --cds-ai-popover-caret-bottom: #78a9ff;
  --cds-ai-popover-caret-bottom-background: #eaf1ff;
  --cds-ai-popover-caret-bottom-background-actions: #e9effa;
  --cds-ai-popover-caret-center: #a0c3ff;
  --cds-ai-popover-shadow-outer-01: #0043ce0f;
  --cds-ai-popover-shadow-outer-02: #0000000a;
  --cds-ai-skeleton-background: #d0e2ff;
  --cds-ai-skeleton-element-background: #4589ff;
  --cds-background: #fff;
  --cds-background-active: #8d8d8d80;
  --cds-background-brand: #0f62fe;
  --cds-background-hover: #8d8d8d1f;
  --cds-background-inverse: #393939;
  --cds-background-inverse-hover: #474747;
  --cds-background-selected: #8d8d8d33;
  --cds-background-selected-hover: #8d8d8d52;
  --cds-border-disabled: #c6c6c6;
  --cds-border-interactive: #0f62fe;
  --cds-border-inverse: #161616;
  --cds-border-strong-01: #8d8d8d;
  --cds-border-strong-02: #8d8d8d;
  --cds-border-strong-03: #8d8d8d;
  --cds-border-subtle-00: #e0e0e0;
  --cds-border-subtle-01: #c6c6c6;
  --cds-border-subtle-02: #e0e0e0;
  --cds-border-subtle-03: #c6c6c6;
  --cds-border-subtle-selected-01: #c6c6c6;
  --cds-border-subtle-selected-02: #c6c6c6;
  --cds-border-subtle-selected-03: #c6c6c6;
  --cds-border-tile-01: #c6c6c6;
  --cds-border-tile-02: #a8a8a8;
  --cds-border-tile-03: #c6c6c6;
  --cds-chat-avatar-agent: #393939;
  --cds-chat-avatar-bot: #6f6f6f;
  --cds-chat-avatar-user: #0f62fe;
  --cds-chat-bubble-agent: #fff;
  --cds-chat-bubble-border: #e0e0e0;
  --cds-chat-bubble-user: #e0e0e0;
  --cds-chat-button: #0f62fe;
  --cds-chat-button-active: #8d8d8d80;
  --cds-chat-button-hover: #8d8d8d1f;
  --cds-chat-button-selected: #8d8d8d33;
  --cds-chat-button-text-hover: #0043ce;
  --cds-chat-button-text-selected: #525252;
  --cds-chat-header-background: #fff;
  --cds-chat-prompt-background: #fff;
  --cds-chat-prompt-border-end: #f4f4f400;
  --cds-chat-prompt-border-start: #f4f4f4;
  --cds-chat-shell-background: #fff;
  --cds-field-01: #f4f4f4;
  --cds-field-02: #fff;
  --cds-field-03: #f4f4f4;
  --cds-field-hover-01: #e8e8e8;
  --cds-field-hover-02: #e8e8e8;
  --cds-field-hover-03: #e8e8e8;
  --cds-focus: #0f62fe;
  --cds-focus-inset: #fff;
  --cds-focus-inverse: #fff;
  --cds-highlight: #d0e2ff;
  --cds-icon-disabled: #16161640;
  --cds-icon-interactive: #0f62fe;
  --cds-icon-inverse: #fff;
  --cds-icon-on-color: #fff;
  --cds-icon-on-color-disabled: #8d8d8d;
  --cds-icon-primary: #161616;
  --cds-icon-secondary: #525252;
  --cds-interactive: #0f62fe;
  --cds-layer-01: #f4f4f4;
  --cds-layer-02: #fff;
  --cds-layer-03: #f4f4f4;
  --cds-layer-accent-01: #e0e0e0;
  --cds-layer-accent-02: #e0e0e0;
  --cds-layer-accent-03: #e0e0e0;
  --cds-layer-accent-active-01: #a8a8a8;
  --cds-layer-accent-active-02: #a8a8a8;
  --cds-layer-accent-active-03: #a8a8a8;
  --cds-layer-accent-hover-01: #d1d1d1;
  --cds-layer-accent-hover-02: #d1d1d1;
  --cds-layer-accent-hover-03: #d1d1d1;
  --cds-layer-active-01: #c6c6c6;
  --cds-layer-active-02: #c6c6c6;
  --cds-layer-active-03: #c6c6c6;
  --cds-layer-hover-01: #e8e8e8;
  --cds-layer-hover-02: #e8e8e8;
  --cds-layer-hover-03: #e8e8e8;
  --cds-layer-selected-01: #e0e0e0;
  --cds-layer-selected-02: #e0e0e0;
  --cds-layer-selected-03: #e0e0e0;
  --cds-layer-selected-disabled: #8d8d8d;
  --cds-layer-selected-hover-01: #d1d1d1;
  --cds-layer-selected-hover-02: #d1d1d1;
  --cds-layer-selected-hover-03: #d1d1d1;
  --cds-layer-selected-inverse: #161616;
  --cds-link-inverse: #78a9ff;
  --cds-link-inverse-active: #f4f4f4;
  --cds-link-inverse-hover: #a6c8ff;
  --cds-link-inverse-visited: #be95ff;
  --cds-link-primary: #0f62fe;
  --cds-link-primary-hover: #0043ce;
  --cds-link-secondary: #0043ce;
  --cds-link-visited: #8a3ffc;
  --cds-overlay: #16161680;
  --cds-shadow: #0000004d;
  --cds-skeleton-background: #e8e8e8;
  --cds-skeleton-element: #c6c6c6;
  --cds-support-caution-major: #ff832b;
  --cds-support-caution-minor: #f1c21b;
  --cds-support-caution-undefined: #8a3ffc;
  --cds-support-error: #da1e28;
  --cds-support-error-inverse: #fa4d56;
  --cds-support-info: #0043ce;
  --cds-support-info-inverse: #4589ff;
  --cds-support-success: #24a148;
  --cds-support-success-inverse: #42be65;
  --cds-support-warning: #f1c21b;
  --cds-support-warning-inverse: #f1c21b;
  --cds-text-disabled: #16161640;
  --cds-text-error: #da1e28;
  --cds-text-helper: #6f6f6f;
  --cds-text-inverse: #fff;
  --cds-text-on-color: #fff;
  --cds-text-on-color-disabled: #8d8d8d;
  --cds-text-placeholder: #16161666;
  --cds-text-primary: #161616;
  --cds-text-secondary: #525252;
  --cds-toggle-off: #8d8d8d;
  --cds-spacing-01: .125rem;
  --cds-spacing-02: .25rem;
  --cds-spacing-03: .5rem;
  --cds-spacing-04: .75rem;
  --cds-spacing-05: 1rem;
  --cds-spacing-06: 1.5rem;
  --cds-spacing-07: 2rem;
  --cds-spacing-08: 2.5rem;
  --cds-spacing-09: 3rem;
  --cds-spacing-10: 4rem;
  --cds-spacing-11: 5rem;
  --cds-spacing-12: 6rem;
  --cds-spacing-13: 10rem;
  --cds-fluid-spacing-01: 0;
  --cds-fluid-spacing-02: 2vw;
  --cds-fluid-spacing-03: 5vw;
  --cds-fluid-spacing-04: 10vw;
  --cds-caption-01-font-size: .75rem;
  --cds-caption-01-font-weight: 400;
  --cds-caption-01-line-height: 1.33333;
  --cds-caption-01-letter-spacing: .32px;
  --cds-caption-02-font-size: .875rem;
  --cds-caption-02-font-weight: 400;
  --cds-caption-02-line-height: 1.28572;
  --cds-caption-02-letter-spacing: .32px;
  --cds-label-01-font-size: .75rem;
  --cds-label-01-font-weight: 400;
  --cds-label-01-line-height: 1.33333;
  --cds-label-01-letter-spacing: .32px;
  --cds-label-02-font-size: .875rem;
  --cds-label-02-font-weight: 400;
  --cds-label-02-line-height: 1.28572;
  --cds-label-02-letter-spacing: .16px;
  --cds-helper-text-01-font-size: .75rem;
  --cds-helper-text-01-line-height: 1.33333;
  --cds-helper-text-01-letter-spacing: .32px;
  --cds-helper-text-02-font-size: .875rem;
  --cds-helper-text-02-font-weight: 400;
  --cds-helper-text-02-line-height: 1.28572;
  --cds-helper-text-02-letter-spacing: .16px;
  --cds-body-short-01-font-size: .875rem;
  --cds-body-short-01-font-weight: 400;
  --cds-body-short-01-line-height: 1.28572;
  --cds-body-short-01-letter-spacing: .16px;
  --cds-body-short-02-font-size: 1rem;
  --cds-body-short-02-font-weight: 400;
  --cds-body-short-02-line-height: 1.375;
  --cds-body-short-02-letter-spacing: 0;
  --cds-body-long-01-font-size: .875rem;
  --cds-body-long-01-font-weight: 400;
  --cds-body-long-01-line-height: 1.42857;
  --cds-body-long-01-letter-spacing: .16px;
  --cds-body-long-02-font-size: 1rem;
  --cds-body-long-02-font-weight: 400;
  --cds-body-long-02-line-height: 1.5;
  --cds-body-long-02-letter-spacing: 0;
  --cds-code-01-font-family: "IBM Plex Mono", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", monospace;
  --cds-code-01-font-size: .75rem;
  --cds-code-01-font-weight: 400;
  --cds-code-01-line-height: 1.33333;
  --cds-code-01-letter-spacing: .32px;
  --cds-code-02-font-family: "IBM Plex Mono", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", monospace;
  --cds-code-02-font-size: .875rem;
  --cds-code-02-font-weight: 400;
  --cds-code-02-line-height: 1.42857;
  --cds-code-02-letter-spacing: .32px;
  --cds-heading-01-font-size: .875rem;
  --cds-heading-01-font-weight: 600;
  --cds-heading-01-line-height: 1.42857;
  --cds-heading-01-letter-spacing: .16px;
  --cds-heading-02-font-size: 1rem;
  --cds-heading-02-font-weight: 600;
  --cds-heading-02-line-height: 1.5;
  --cds-heading-02-letter-spacing: 0;
  --cds-productive-heading-01-font-size: .875rem;
  --cds-productive-heading-01-font-weight: 600;
  --cds-productive-heading-01-line-height: 1.28572;
  --cds-productive-heading-01-letter-spacing: .16px;
  --cds-productive-heading-02-font-size: 1rem;
  --cds-productive-heading-02-font-weight: 600;
  --cds-productive-heading-02-line-height: 1.375;
  --cds-productive-heading-02-letter-spacing: 0;
  --cds-productive-heading-03-font-size: 1.25rem;
  --cds-productive-heading-03-font-weight: 400;
  --cds-productive-heading-03-line-height: 1.4;
  --cds-productive-heading-03-letter-spacing: 0;
  --cds-productive-heading-04-font-size: 1.75rem;
  --cds-productive-heading-04-font-weight: 400;
  --cds-productive-heading-04-line-height: 1.28572;
  --cds-productive-heading-04-letter-spacing: 0;
  --cds-productive-heading-05-font-size: 2rem;
  --cds-productive-heading-05-font-weight: 400;
  --cds-productive-heading-05-line-height: 1.25;
  --cds-productive-heading-05-letter-spacing: 0;
  --cds-productive-heading-06-font-size: 2.625rem;
  --cds-productive-heading-06-font-weight: 300;
  --cds-productive-heading-06-line-height: 1.199;
  --cds-productive-heading-06-letter-spacing: 0;
  --cds-productive-heading-07-font-size: 3.375rem;
  --cds-productive-heading-07-font-weight: 300;
  --cds-productive-heading-07-line-height: 1.19;
  --cds-productive-heading-07-letter-spacing: 0;
  --cds-expressive-paragraph-01-font-size: 1.5rem;
  --cds-expressive-paragraph-01-font-weight: 300;
  --cds-expressive-paragraph-01-line-height: 1.334;
  --cds-expressive-paragraph-01-letter-spacing: 0;
  --cds-expressive-heading-01-font-size: .875rem;
  --cds-expressive-heading-01-font-weight: 600;
  --cds-expressive-heading-01-line-height: 1.42857;
  --cds-expressive-heading-01-letter-spacing: .16px;
  --cds-expressive-heading-02-font-size: 1rem;
  --cds-expressive-heading-02-font-weight: 600;
  --cds-expressive-heading-02-line-height: 1.5;
  --cds-expressive-heading-02-letter-spacing: 0;
  --cds-expressive-heading-03-font-size: 1.25rem;
  --cds-expressive-heading-03-font-weight: 400;
  --cds-expressive-heading-03-line-height: 1.4;
  --cds-expressive-heading-03-letter-spacing: 0;
  --cds-expressive-heading-04-font-size: 1.75rem;
  --cds-expressive-heading-04-font-weight: 400;
  --cds-expressive-heading-04-line-height: 1.28572;
  --cds-expressive-heading-04-letter-spacing: 0;
  --cds-expressive-heading-05-font-size: 2rem;
  --cds-expressive-heading-05-font-weight: 400;
  --cds-expressive-heading-05-line-height: 1.25;
  --cds-expressive-heading-05-letter-spacing: 0;
  --cds-expressive-heading-06-font-size: 2rem;
  --cds-expressive-heading-06-font-weight: 600;
  --cds-expressive-heading-06-line-height: 1.25;
  --cds-expressive-heading-06-letter-spacing: 0;
  --cds-quotation-01-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-quotation-01-font-size: 1.25rem;
  --cds-quotation-01-font-weight: 400;
  --cds-quotation-01-line-height: 1.3;
  --cds-quotation-01-letter-spacing: 0;
  --cds-quotation-02-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-quotation-02-font-size: 2rem;
  --cds-quotation-02-font-weight: 300;
  --cds-quotation-02-line-height: 1.25;
  --cds-quotation-02-letter-spacing: 0;
  --cds-display-01-font-size: 2.625rem;
  --cds-display-01-font-weight: 300;
  --cds-display-01-line-height: 1.19;
  --cds-display-01-letter-spacing: 0;
  --cds-display-02-font-size: 2.625rem;
  --cds-display-02-font-weight: 600;
  --cds-display-02-line-height: 1.19;
  --cds-display-02-letter-spacing: 0;
  --cds-display-03-font-size: 2.625rem;
  --cds-display-03-font-weight: 300;
  --cds-display-03-line-height: 1.19;
  --cds-display-03-letter-spacing: 0;
  --cds-display-04-font-size: 2.625rem;
  --cds-display-04-font-weight: 300;
  --cds-display-04-line-height: 1.19;
  --cds-display-04-letter-spacing: 0;
  --cds-legal-01-font-size: .75rem;
  --cds-legal-01-font-weight: 400;
  --cds-legal-01-line-height: 1.33333;
  --cds-legal-01-letter-spacing: .32px;
  --cds-legal-02-font-size: .875rem;
  --cds-legal-02-font-weight: 400;
  --cds-legal-02-line-height: 1.28572;
  --cds-legal-02-letter-spacing: .16px;
  --cds-body-compact-01-font-size: .875rem;
  --cds-body-compact-01-font-weight: 400;
  --cds-body-compact-01-line-height: 1.28572;
  --cds-body-compact-01-letter-spacing: .16px;
  --cds-body-compact-02-font-size: 1rem;
  --cds-body-compact-02-font-weight: 400;
  --cds-body-compact-02-line-height: 1.375;
  --cds-body-compact-02-letter-spacing: 0;
  --cds-heading-compact-01-font-size: .875rem;
  --cds-heading-compact-01-font-weight: 600;
  --cds-heading-compact-01-line-height: 1.28572;
  --cds-heading-compact-01-letter-spacing: .16px;
  --cds-heading-compact-02-font-size: 1rem;
  --cds-heading-compact-02-font-weight: 600;
  --cds-heading-compact-02-line-height: 1.375;
  --cds-heading-compact-02-letter-spacing: 0;
  --cds-body-01-font-size: .875rem;
  --cds-body-01-font-weight: 400;
  --cds-body-01-line-height: 1.42857;
  --cds-body-01-letter-spacing: .16px;
  --cds-body-02-font-size: 1rem;
  --cds-body-02-font-weight: 400;
  --cds-body-02-line-height: 1.5;
  --cds-body-02-letter-spacing: 0;
  --cds-heading-03-font-size: 1.25rem;
  --cds-heading-03-font-weight: 400;
  --cds-heading-03-line-height: 1.4;
  --cds-heading-03-letter-spacing: 0;
  --cds-heading-04-font-size: 1.75rem;
  --cds-heading-04-font-weight: 400;
  --cds-heading-04-line-height: 1.28572;
  --cds-heading-04-letter-spacing: 0;
  --cds-heading-05-font-size: 2rem;
  --cds-heading-05-font-weight: 400;
  --cds-heading-05-line-height: 1.25;
  --cds-heading-05-letter-spacing: 0;
  --cds-heading-06-font-size: 2.625rem;
  --cds-heading-06-font-weight: 300;
  --cds-heading-06-line-height: 1.199;
  --cds-heading-06-letter-spacing: 0;
  --cds-heading-07-font-size: 3.375rem;
  --cds-heading-07-font-weight: 300;
  --cds-heading-07-line-height: 1.19;
  --cds-heading-07-letter-spacing: 0;
  --cds-fluid-heading-03-font-size: 1.25rem;
  --cds-fluid-heading-03-font-weight: 400;
  --cds-fluid-heading-03-line-height: 1.4;
  --cds-fluid-heading-03-letter-spacing: 0;
  --cds-fluid-heading-04-font-size: 1.75rem;
  --cds-fluid-heading-04-font-weight: 400;
  --cds-fluid-heading-04-line-height: 1.28572;
  --cds-fluid-heading-04-letter-spacing: 0;
  --cds-fluid-heading-05-font-size: 2rem;
  --cds-fluid-heading-05-font-weight: 400;
  --cds-fluid-heading-05-line-height: 1.25;
  --cds-fluid-heading-05-letter-spacing: 0;
  --cds-fluid-heading-06-font-size: 2rem;
  --cds-fluid-heading-06-font-weight: 600;
  --cds-fluid-heading-06-line-height: 1.25;
  --cds-fluid-heading-06-letter-spacing: 0;
  --cds-fluid-paragraph-01-font-size: 1.5rem;
  --cds-fluid-paragraph-01-font-weight: 300;
  --cds-fluid-paragraph-01-line-height: 1.334;
  --cds-fluid-paragraph-01-letter-spacing: 0;
  --cds-fluid-quotation-01-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-fluid-quotation-01-font-size: 1.25rem;
  --cds-fluid-quotation-01-font-weight: 400;
  --cds-fluid-quotation-01-line-height: 1.3;
  --cds-fluid-quotation-01-letter-spacing: 0;
  --cds-fluid-quotation-02-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-fluid-quotation-02-font-size: 2rem;
  --cds-fluid-quotation-02-font-weight: 300;
  --cds-fluid-quotation-02-line-height: 1.25;
  --cds-fluid-quotation-02-letter-spacing: 0;
  --cds-fluid-display-01-font-size: 2.625rem;
  --cds-fluid-display-01-font-weight: 300;
  --cds-fluid-display-01-line-height: 1.19;
  --cds-fluid-display-01-letter-spacing: 0;
  --cds-fluid-display-02-font-size: 2.625rem;
  --cds-fluid-display-02-font-weight: 600;
  --cds-fluid-display-02-line-height: 1.19;
  --cds-fluid-display-02-letter-spacing: 0;
  --cds-fluid-display-03-font-size: 2.625rem;
  --cds-fluid-display-03-font-weight: 300;
  --cds-fluid-display-03-line-height: 1.19;
  --cds-fluid-display-03-letter-spacing: 0;
  --cds-fluid-display-04-font-size: 2.625rem;
  --cds-fluid-display-04-font-weight: 300;
  --cds-fluid-display-04-line-height: 1.19;
  --cds-fluid-display-04-letter-spacing: 0;
  color: var(--cds-text-primary, #161616);
  background: var(--cds-background, white);
}

.cds-theme-zone-g10 {
  --cds-ai-aura-end: #fff0;
  --cds-ai-aura-hover-background: #edf5ff;
  --cds-ai-aura-hover-end: #fff0;
  --cds-ai-aura-hover-start: #4589ff52;
  --cds-ai-aura-start: #4589ff1a;
  --cds-ai-aura-start-sm: #4589ff29;
  --cds-ai-border-end: #78a9ff;
  --cds-ai-border-start: #a6c8ffa3;
  --cds-ai-border-strong: #4589ff;
  --cds-ai-drop-shadow: #0f62fe1a;
  --cds-ai-inner-shadow: #4589ff1a;
  --cds-ai-overlay: #00114180;
  --cds-ai-popover-background: #fff;
  --cds-ai-popover-caret-bottom: #78a9ff;
  --cds-ai-popover-caret-bottom-background: #eaf1ff;
  --cds-ai-popover-caret-bottom-background-actions: #e9effa;
  --cds-ai-popover-caret-center: #a0c3ff;
  --cds-ai-popover-shadow-outer-01: #0043ce0f;
  --cds-ai-popover-shadow-outer-02: #0000000a;
  --cds-ai-skeleton-background: #d0e2ff;
  --cds-ai-skeleton-element-background: #4589ff;
  --cds-background: #f4f4f4;
  --cds-background-active: #8d8d8d80;
  --cds-background-brand: #0f62fe;
  --cds-background-hover: #8d8d8d1f;
  --cds-background-inverse: #393939;
  --cds-background-inverse-hover: #474747;
  --cds-background-selected: #8d8d8d33;
  --cds-background-selected-hover: #8d8d8d52;
  --cds-border-disabled: #c6c6c6;
  --cds-border-interactive: #0f62fe;
  --cds-border-inverse: #161616;
  --cds-border-strong-01: #8d8d8d;
  --cds-border-strong-02: #8d8d8d;
  --cds-border-strong-03: #8d8d8d;
  --cds-border-subtle-00: #c6c6c6;
  --cds-border-subtle-01: #e0e0e0;
  --cds-border-subtle-02: #c6c6c6;
  --cds-border-subtle-03: #e0e0e0;
  --cds-border-subtle-selected-01: #c6c6c6;
  --cds-border-subtle-selected-02: #c6c6c6;
  --cds-border-subtle-selected-03: #c6c6c6;
  --cds-border-tile-01: #a8a8a8;
  --cds-border-tile-02: #c6c6c6;
  --cds-border-tile-03: #a8a8a8;
  --cds-chat-avatar-agent: #393939;
  --cds-chat-avatar-bot: #6f6f6f;
  --cds-chat-avatar-user: #0f62fe;
  --cds-chat-bubble-agent: #fff;
  --cds-chat-bubble-border: #e0e0e0;
  --cds-chat-bubble-user: #e0e0e0;
  --cds-chat-button: #0f62fe;
  --cds-chat-button-active: #8d8d8d80;
  --cds-chat-button-hover: #8d8d8d1f;
  --cds-chat-button-selected: #8d8d8d33;
  --cds-chat-button-text-hover: #0043ce;
  --cds-chat-button-text-selected: #525252;
  --cds-chat-header-background: #fff;
  --cds-chat-prompt-background: #fff;
  --cds-chat-prompt-border-end: #f4f4f400;
  --cds-chat-prompt-border-start: #f4f4f4;
  --cds-chat-shell-background: #fff;
  --cds-field-01: #fff;
  --cds-field-02: #f4f4f4;
  --cds-field-03: #fff;
  --cds-field-hover-01: #e8e8e8;
  --cds-field-hover-02: #e8e8e8;
  --cds-field-hover-03: #e8e8e8;
  --cds-focus: #0f62fe;
  --cds-focus-inset: #fff;
  --cds-focus-inverse: #fff;
  --cds-highlight: #d0e2ff;
  --cds-icon-disabled: #16161640;
  --cds-icon-interactive: #0f62fe;
  --cds-icon-inverse: #fff;
  --cds-icon-on-color: #fff;
  --cds-icon-on-color-disabled: #8d8d8d;
  --cds-icon-primary: #161616;
  --cds-icon-secondary: #525252;
  --cds-interactive: #0f62fe;
  --cds-layer-01: #fff;
  --cds-layer-02: #f4f4f4;
  --cds-layer-03: #fff;
  --cds-layer-accent-01: #e0e0e0;
  --cds-layer-accent-02: #e0e0e0;
  --cds-layer-accent-03: #e0e0e0;
  --cds-layer-accent-active-01: #a8a8a8;
  --cds-layer-accent-active-02: #a8a8a8;
  --cds-layer-accent-active-03: #a8a8a8;
  --cds-layer-accent-hover-01: #d1d1d1;
  --cds-layer-accent-hover-02: #d1d1d1;
  --cds-layer-accent-hover-03: #d1d1d1;
  --cds-layer-active-01: #c6c6c6;
  --cds-layer-active-02: #c6c6c6;
  --cds-layer-active-03: #c6c6c6;
  --cds-layer-hover-01: #e8e8e8;
  --cds-layer-hover-02: #e8e8e8;
  --cds-layer-hover-03: #e8e8e8;
  --cds-layer-selected-01: #e0e0e0;
  --cds-layer-selected-02: #e0e0e0;
  --cds-layer-selected-03: #e0e0e0;
  --cds-layer-selected-disabled: #8d8d8d;
  --cds-layer-selected-hover-01: #d1d1d1;
  --cds-layer-selected-hover-02: #d1d1d1;
  --cds-layer-selected-hover-03: #d1d1d1;
  --cds-layer-selected-inverse: #161616;
  --cds-link-inverse: #78a9ff;
  --cds-link-inverse-active: #f4f4f4;
  --cds-link-inverse-hover: #a6c8ff;
  --cds-link-inverse-visited: #be95ff;
  --cds-link-primary: #0f62fe;
  --cds-link-primary-hover: #0043ce;
  --cds-link-secondary: #0043ce;
  --cds-link-visited: #8a3ffc;
  --cds-overlay: #16161680;
  --cds-shadow: #0000004d;
  --cds-skeleton-background: #e8e8e8;
  --cds-skeleton-element: #c6c6c6;
  --cds-support-caution-major: #ff832b;
  --cds-support-caution-minor: #f1c21b;
  --cds-support-caution-undefined: #8a3ffc;
  --cds-support-error: #da1e28;
  --cds-support-error-inverse: #fa4d56;
  --cds-support-info: #0043ce;
  --cds-support-info-inverse: #4589ff;
  --cds-support-success: #24a148;
  --cds-support-success-inverse: #42be65;
  --cds-support-warning: #f1c21b;
  --cds-support-warning-inverse: #f1c21b;
  --cds-text-disabled: #16161640;
  --cds-text-error: #da1e28;
  --cds-text-helper: #6f6f6f;
  --cds-text-inverse: #fff;
  --cds-text-on-color: #fff;
  --cds-text-on-color-disabled: #8d8d8d;
  --cds-text-placeholder: #16161666;
  --cds-text-primary: #161616;
  --cds-text-secondary: #525252;
  --cds-toggle-off: #8d8d8d;
  --cds-spacing-01: .125rem;
  --cds-spacing-02: .25rem;
  --cds-spacing-03: .5rem;
  --cds-spacing-04: .75rem;
  --cds-spacing-05: 1rem;
  --cds-spacing-06: 1.5rem;
  --cds-spacing-07: 2rem;
  --cds-spacing-08: 2.5rem;
  --cds-spacing-09: 3rem;
  --cds-spacing-10: 4rem;
  --cds-spacing-11: 5rem;
  --cds-spacing-12: 6rem;
  --cds-spacing-13: 10rem;
  --cds-fluid-spacing-01: 0;
  --cds-fluid-spacing-02: 2vw;
  --cds-fluid-spacing-03: 5vw;
  --cds-fluid-spacing-04: 10vw;
  --cds-caption-01-font-size: .75rem;
  --cds-caption-01-font-weight: 400;
  --cds-caption-01-line-height: 1.33333;
  --cds-caption-01-letter-spacing: .32px;
  --cds-caption-02-font-size: .875rem;
  --cds-caption-02-font-weight: 400;
  --cds-caption-02-line-height: 1.28572;
  --cds-caption-02-letter-spacing: .32px;
  --cds-label-01-font-size: .75rem;
  --cds-label-01-font-weight: 400;
  --cds-label-01-line-height: 1.33333;
  --cds-label-01-letter-spacing: .32px;
  --cds-label-02-font-size: .875rem;
  --cds-label-02-font-weight: 400;
  --cds-label-02-line-height: 1.28572;
  --cds-label-02-letter-spacing: .16px;
  --cds-helper-text-01-font-size: .75rem;
  --cds-helper-text-01-line-height: 1.33333;
  --cds-helper-text-01-letter-spacing: .32px;
  --cds-helper-text-02-font-size: .875rem;
  --cds-helper-text-02-font-weight: 400;
  --cds-helper-text-02-line-height: 1.28572;
  --cds-helper-text-02-letter-spacing: .16px;
  --cds-body-short-01-font-size: .875rem;
  --cds-body-short-01-font-weight: 400;
  --cds-body-short-01-line-height: 1.28572;
  --cds-body-short-01-letter-spacing: .16px;
  --cds-body-short-02-font-size: 1rem;
  --cds-body-short-02-font-weight: 400;
  --cds-body-short-02-line-height: 1.375;
  --cds-body-short-02-letter-spacing: 0;
  --cds-body-long-01-font-size: .875rem;
  --cds-body-long-01-font-weight: 400;
  --cds-body-long-01-line-height: 1.42857;
  --cds-body-long-01-letter-spacing: .16px;
  --cds-body-long-02-font-size: 1rem;
  --cds-body-long-02-font-weight: 400;
  --cds-body-long-02-line-height: 1.5;
  --cds-body-long-02-letter-spacing: 0;
  --cds-code-01-font-family: "IBM Plex Mono", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", monospace;
  --cds-code-01-font-size: .75rem;
  --cds-code-01-font-weight: 400;
  --cds-code-01-line-height: 1.33333;
  --cds-code-01-letter-spacing: .32px;
  --cds-code-02-font-family: "IBM Plex Mono", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", monospace;
  --cds-code-02-font-size: .875rem;
  --cds-code-02-font-weight: 400;
  --cds-code-02-line-height: 1.42857;
  --cds-code-02-letter-spacing: .32px;
  --cds-heading-01-font-size: .875rem;
  --cds-heading-01-font-weight: 600;
  --cds-heading-01-line-height: 1.42857;
  --cds-heading-01-letter-spacing: .16px;
  --cds-heading-02-font-size: 1rem;
  --cds-heading-02-font-weight: 600;
  --cds-heading-02-line-height: 1.5;
  --cds-heading-02-letter-spacing: 0;
  --cds-productive-heading-01-font-size: .875rem;
  --cds-productive-heading-01-font-weight: 600;
  --cds-productive-heading-01-line-height: 1.28572;
  --cds-productive-heading-01-letter-spacing: .16px;
  --cds-productive-heading-02-font-size: 1rem;
  --cds-productive-heading-02-font-weight: 600;
  --cds-productive-heading-02-line-height: 1.375;
  --cds-productive-heading-02-letter-spacing: 0;
  --cds-productive-heading-03-font-size: 1.25rem;
  --cds-productive-heading-03-font-weight: 400;
  --cds-productive-heading-03-line-height: 1.4;
  --cds-productive-heading-03-letter-spacing: 0;
  --cds-productive-heading-04-font-size: 1.75rem;
  --cds-productive-heading-04-font-weight: 400;
  --cds-productive-heading-04-line-height: 1.28572;
  --cds-productive-heading-04-letter-spacing: 0;
  --cds-productive-heading-05-font-size: 2rem;
  --cds-productive-heading-05-font-weight: 400;
  --cds-productive-heading-05-line-height: 1.25;
  --cds-productive-heading-05-letter-spacing: 0;
  --cds-productive-heading-06-font-size: 2.625rem;
  --cds-productive-heading-06-font-weight: 300;
  --cds-productive-heading-06-line-height: 1.199;
  --cds-productive-heading-06-letter-spacing: 0;
  --cds-productive-heading-07-font-size: 3.375rem;
  --cds-productive-heading-07-font-weight: 300;
  --cds-productive-heading-07-line-height: 1.19;
  --cds-productive-heading-07-letter-spacing: 0;
  --cds-expressive-paragraph-01-font-size: 1.5rem;
  --cds-expressive-paragraph-01-font-weight: 300;
  --cds-expressive-paragraph-01-line-height: 1.334;
  --cds-expressive-paragraph-01-letter-spacing: 0;
  --cds-expressive-heading-01-font-size: .875rem;
  --cds-expressive-heading-01-font-weight: 600;
  --cds-expressive-heading-01-line-height: 1.42857;
  --cds-expressive-heading-01-letter-spacing: .16px;
  --cds-expressive-heading-02-font-size: 1rem;
  --cds-expressive-heading-02-font-weight: 600;
  --cds-expressive-heading-02-line-height: 1.5;
  --cds-expressive-heading-02-letter-spacing: 0;
  --cds-expressive-heading-03-font-size: 1.25rem;
  --cds-expressive-heading-03-font-weight: 400;
  --cds-expressive-heading-03-line-height: 1.4;
  --cds-expressive-heading-03-letter-spacing: 0;
  --cds-expressive-heading-04-font-size: 1.75rem;
  --cds-expressive-heading-04-font-weight: 400;
  --cds-expressive-heading-04-line-height: 1.28572;
  --cds-expressive-heading-04-letter-spacing: 0;
  --cds-expressive-heading-05-font-size: 2rem;
  --cds-expressive-heading-05-font-weight: 400;
  --cds-expressive-heading-05-line-height: 1.25;
  --cds-expressive-heading-05-letter-spacing: 0;
  --cds-expressive-heading-06-font-size: 2rem;
  --cds-expressive-heading-06-font-weight: 600;
  --cds-expressive-heading-06-line-height: 1.25;
  --cds-expressive-heading-06-letter-spacing: 0;
  --cds-quotation-01-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-quotation-01-font-size: 1.25rem;
  --cds-quotation-01-font-weight: 400;
  --cds-quotation-01-line-height: 1.3;
  --cds-quotation-01-letter-spacing: 0;
  --cds-quotation-02-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-quotation-02-font-size: 2rem;
  --cds-quotation-02-font-weight: 300;
  --cds-quotation-02-line-height: 1.25;
  --cds-quotation-02-letter-spacing: 0;
  --cds-display-01-font-size: 2.625rem;
  --cds-display-01-font-weight: 300;
  --cds-display-01-line-height: 1.19;
  --cds-display-01-letter-spacing: 0;
  --cds-display-02-font-size: 2.625rem;
  --cds-display-02-font-weight: 600;
  --cds-display-02-line-height: 1.19;
  --cds-display-02-letter-spacing: 0;
  --cds-display-03-font-size: 2.625rem;
  --cds-display-03-font-weight: 300;
  --cds-display-03-line-height: 1.19;
  --cds-display-03-letter-spacing: 0;
  --cds-display-04-font-size: 2.625rem;
  --cds-display-04-font-weight: 300;
  --cds-display-04-line-height: 1.19;
  --cds-display-04-letter-spacing: 0;
  --cds-legal-01-font-size: .75rem;
  --cds-legal-01-font-weight: 400;
  --cds-legal-01-line-height: 1.33333;
  --cds-legal-01-letter-spacing: .32px;
  --cds-legal-02-font-size: .875rem;
  --cds-legal-02-font-weight: 400;
  --cds-legal-02-line-height: 1.28572;
  --cds-legal-02-letter-spacing: .16px;
  --cds-body-compact-01-font-size: .875rem;
  --cds-body-compact-01-font-weight: 400;
  --cds-body-compact-01-line-height: 1.28572;
  --cds-body-compact-01-letter-spacing: .16px;
  --cds-body-compact-02-font-size: 1rem;
  --cds-body-compact-02-font-weight: 400;
  --cds-body-compact-02-line-height: 1.375;
  --cds-body-compact-02-letter-spacing: 0;
  --cds-heading-compact-01-font-size: .875rem;
  --cds-heading-compact-01-font-weight: 600;
  --cds-heading-compact-01-line-height: 1.28572;
  --cds-heading-compact-01-letter-spacing: .16px;
  --cds-heading-compact-02-font-size: 1rem;
  --cds-heading-compact-02-font-weight: 600;
  --cds-heading-compact-02-line-height: 1.375;
  --cds-heading-compact-02-letter-spacing: 0;
  --cds-body-01-font-size: .875rem;
  --cds-body-01-font-weight: 400;
  --cds-body-01-line-height: 1.42857;
  --cds-body-01-letter-spacing: .16px;
  --cds-body-02-font-size: 1rem;
  --cds-body-02-font-weight: 400;
  --cds-body-02-line-height: 1.5;
  --cds-body-02-letter-spacing: 0;
  --cds-heading-03-font-size: 1.25rem;
  --cds-heading-03-font-weight: 400;
  --cds-heading-03-line-height: 1.4;
  --cds-heading-03-letter-spacing: 0;
  --cds-heading-04-font-size: 1.75rem;
  --cds-heading-04-font-weight: 400;
  --cds-heading-04-line-height: 1.28572;
  --cds-heading-04-letter-spacing: 0;
  --cds-heading-05-font-size: 2rem;
  --cds-heading-05-font-weight: 400;
  --cds-heading-05-line-height: 1.25;
  --cds-heading-05-letter-spacing: 0;
  --cds-heading-06-font-size: 2.625rem;
  --cds-heading-06-font-weight: 300;
  --cds-heading-06-line-height: 1.199;
  --cds-heading-06-letter-spacing: 0;
  --cds-heading-07-font-size: 3.375rem;
  --cds-heading-07-font-weight: 300;
  --cds-heading-07-line-height: 1.19;
  --cds-heading-07-letter-spacing: 0;
  --cds-fluid-heading-03-font-size: 1.25rem;
  --cds-fluid-heading-03-font-weight: 400;
  --cds-fluid-heading-03-line-height: 1.4;
  --cds-fluid-heading-03-letter-spacing: 0;
  --cds-fluid-heading-04-font-size: 1.75rem;
  --cds-fluid-heading-04-font-weight: 400;
  --cds-fluid-heading-04-line-height: 1.28572;
  --cds-fluid-heading-04-letter-spacing: 0;
  --cds-fluid-heading-05-font-size: 2rem;
  --cds-fluid-heading-05-font-weight: 400;
  --cds-fluid-heading-05-line-height: 1.25;
  --cds-fluid-heading-05-letter-spacing: 0;
  --cds-fluid-heading-06-font-size: 2rem;
  --cds-fluid-heading-06-font-weight: 600;
  --cds-fluid-heading-06-line-height: 1.25;
  --cds-fluid-heading-06-letter-spacing: 0;
  --cds-fluid-paragraph-01-font-size: 1.5rem;
  --cds-fluid-paragraph-01-font-weight: 300;
  --cds-fluid-paragraph-01-line-height: 1.334;
  --cds-fluid-paragraph-01-letter-spacing: 0;
  --cds-fluid-quotation-01-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-fluid-quotation-01-font-size: 1.25rem;
  --cds-fluid-quotation-01-font-weight: 400;
  --cds-fluid-quotation-01-line-height: 1.3;
  --cds-fluid-quotation-01-letter-spacing: 0;
  --cds-fluid-quotation-02-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-fluid-quotation-02-font-size: 2rem;
  --cds-fluid-quotation-02-font-weight: 300;
  --cds-fluid-quotation-02-line-height: 1.25;
  --cds-fluid-quotation-02-letter-spacing: 0;
  --cds-fluid-display-01-font-size: 2.625rem;
  --cds-fluid-display-01-font-weight: 300;
  --cds-fluid-display-01-line-height: 1.19;
  --cds-fluid-display-01-letter-spacing: 0;
  --cds-fluid-display-02-font-size: 2.625rem;
  --cds-fluid-display-02-font-weight: 600;
  --cds-fluid-display-02-line-height: 1.19;
  --cds-fluid-display-02-letter-spacing: 0;
  --cds-fluid-display-03-font-size: 2.625rem;
  --cds-fluid-display-03-font-weight: 300;
  --cds-fluid-display-03-line-height: 1.19;
  --cds-fluid-display-03-letter-spacing: 0;
  --cds-fluid-display-04-font-size: 2.625rem;
  --cds-fluid-display-04-font-weight: 300;
  --cds-fluid-display-04-line-height: 1.19;
  --cds-fluid-display-04-letter-spacing: 0;
  color: var(--cds-text-primary, #161616);
  background: var(--cds-background, white);
}

.cds-theme-zone-g90 {
  --cds-ai-aura-end: #0000;
  --cds-ai-aura-hover-background: #474747;
  --cds-ai-aura-hover-end: #0000;
  --cds-ai-aura-hover-start: #4589ff66;
  --cds-ai-aura-start: #4589ff1a;
  --cds-ai-aura-start-sm: #4589ff29;
  --cds-ai-border-end: #4589ff;
  --cds-ai-border-start: #a6c8ff5c;
  --cds-ai-border-strong: #78a9ff;
  --cds-ai-drop-shadow: #00000047;
  --cds-ai-inner-shadow: #4589ff29;
  --cds-ai-overlay: #00000080;
  --cds-ai-popover-background: #161616;
  --cds-ai-popover-caret-bottom: #4589ff;
  --cds-ai-popover-caret-bottom-background: #202d45;
  --cds-ai-popover-caret-bottom-background-actions: #1e283a;
  --cds-ai-popover-caret-center: #4870b5;
  --cds-ai-popover-shadow-outer-01: #0000001f;
  --cds-ai-popover-shadow-outer-02: #00000014;
  --cds-ai-skeleton-background: #78a9ff80;
  --cds-ai-skeleton-element-background: #78a9ff4d;
  --cds-background: #262626;
  --cds-background-active: #8d8d8d66;
  --cds-background-brand: #0f62fe;
  --cds-background-hover: #8d8d8d29;
  --cds-background-inverse: #f4f4f4;
  --cds-background-inverse-hover: #e8e8e8;
  --cds-background-selected: #8d8d8d3d;
  --cds-background-selected-hover: #8d8d8d52;
  --cds-border-disabled: #8d8d8d80;
  --cds-border-interactive: #4589ff;
  --cds-border-inverse: #f4f4f4;
  --cds-border-strong-01: #8d8d8d;
  --cds-border-strong-02: #a8a8a8;
  --cds-border-strong-03: #c6c6c6;
  --cds-border-subtle-00: #525252;
  --cds-border-subtle-01: #6f6f6f;
  --cds-border-subtle-02: #8d8d8d;
  --cds-border-subtle-03: #8d8d8d;
  --cds-border-subtle-selected-01: #8d8d8d;
  --cds-border-subtle-selected-02: #a8a8a8;
  --cds-border-subtle-selected-03: #a8a8a8;
  --cds-border-tile-01: #6f6f6f;
  --cds-border-tile-02: #8d8d8d;
  --cds-border-tile-03: #a8a8a8;
  --cds-chat-avatar-agent: #c6c6c6;
  --cds-chat-avatar-bot: #8d8d8d;
  --cds-chat-avatar-user: #4589ff;
  --cds-chat-bubble-agent: #262626;
  --cds-chat-bubble-border: #525252;
  --cds-chat-bubble-user: #393939;
  --cds-chat-button: #78a9ff;
  --cds-chat-button-active: #8d8d8d66;
  --cds-chat-button-hover: #8d8d8d29;
  --cds-chat-button-selected: #8d8d8d3d;
  --cds-chat-button-text-hover: #a6c8ff;
  --cds-chat-button-text-selected: #c6c6c6;
  --cds-chat-header-background: #262626;
  --cds-chat-prompt-background: #161616;
  --cds-chat-prompt-border-end: #26262600;
  --cds-chat-prompt-border-start: #262626;
  --cds-chat-shell-background: #262626;
  --cds-field-01: #393939;
  --cds-field-02: #525252;
  --cds-field-03: #6f6f6f;
  --cds-field-hover-01: #474747;
  --cds-field-hover-02: #636363;
  --cds-field-hover-03: #5e5e5e;
  --cds-focus: #fff;
  --cds-focus-inset: #161616;
  --cds-focus-inverse: #0f62fe;
  --cds-highlight: #0043ce;
  --cds-icon-disabled: #f4f4f440;
  --cds-icon-interactive: #fff;
  --cds-icon-inverse: #161616;
  --cds-icon-on-color: #fff;
  --cds-icon-on-color-disabled: #ffffff40;
  --cds-icon-primary: #f4f4f4;
  --cds-icon-secondary: #c6c6c6;
  --cds-interactive: #4589ff;
  --cds-layer-01: #393939;
  --cds-layer-02: #525252;
  --cds-layer-03: #6f6f6f;
  --cds-layer-accent-01: #525252;
  --cds-layer-accent-02: #6f6f6f;
  --cds-layer-accent-03: #8d8d8d;
  --cds-layer-accent-active-01: #8d8d8d;
  --cds-layer-accent-active-02: #393939;
  --cds-layer-accent-active-03: #525252;
  --cds-layer-accent-hover-01: #636363;
  --cds-layer-accent-hover-02: #5e5e5e;
  --cds-layer-accent-hover-03: #7a7a7a;
  --cds-layer-active-01: #6f6f6f;
  --cds-layer-active-02: #8d8d8d;
  --cds-layer-active-03: #393939;
  --cds-layer-hover-01: #474747;
  --cds-layer-hover-02: #636363;
  --cds-layer-hover-03: #5e5e5e;
  --cds-layer-selected-01: #525252;
  --cds-layer-selected-02: #6f6f6f;
  --cds-layer-selected-03: #525252;
  --cds-layer-selected-disabled: #a8a8a8;
  --cds-layer-selected-hover-01: #636363;
  --cds-layer-selected-hover-02: #5e5e5e;
  --cds-layer-selected-hover-03: #636363;
  --cds-layer-selected-inverse: #f4f4f4;
  --cds-link-inverse: #0f62fe;
  --cds-link-inverse-active: #161616;
  --cds-link-inverse-hover: #0043ce;
  --cds-link-inverse-visited: #8a3ffc;
  --cds-link-primary: #78a9ff;
  --cds-link-primary-hover: #a6c8ff;
  --cds-link-secondary: #a6c8ff;
  --cds-link-visited: #be95ff;
  --cds-overlay: #000000a6;
  --cds-shadow: #000c;
  --cds-skeleton-background: #333;
  --cds-skeleton-element: #525252;
  --cds-support-caution-major: #ff832b;
  --cds-support-caution-minor: #f1c21b;
  --cds-support-caution-undefined: #a56eff;
  --cds-support-error: #ff8389;
  --cds-support-error-inverse: #da1e28;
  --cds-support-info: #4589ff;
  --cds-support-info-inverse: #0043ce;
  --cds-support-success: #42be65;
  --cds-support-success-inverse: #24a148;
  --cds-support-warning: #f1c21b;
  --cds-support-warning-inverse: #f1c21b;
  --cds-text-disabled: #f4f4f440;
  --cds-text-error: #ffb3b8;
  --cds-text-helper: #c6c6c6;
  --cds-text-inverse: #161616;
  --cds-text-on-color: #fff;
  --cds-text-on-color-disabled: #ffffff40;
  --cds-text-placeholder: #f4f4f466;
  --cds-text-primary: #f4f4f4;
  --cds-text-secondary: #c6c6c6;
  --cds-toggle-off: #8d8d8d;
  --cds-spacing-01: .125rem;
  --cds-spacing-02: .25rem;
  --cds-spacing-03: .5rem;
  --cds-spacing-04: .75rem;
  --cds-spacing-05: 1rem;
  --cds-spacing-06: 1.5rem;
  --cds-spacing-07: 2rem;
  --cds-spacing-08: 2.5rem;
  --cds-spacing-09: 3rem;
  --cds-spacing-10: 4rem;
  --cds-spacing-11: 5rem;
  --cds-spacing-12: 6rem;
  --cds-spacing-13: 10rem;
  --cds-fluid-spacing-01: 0;
  --cds-fluid-spacing-02: 2vw;
  --cds-fluid-spacing-03: 5vw;
  --cds-fluid-spacing-04: 10vw;
  --cds-caption-01-font-size: .75rem;
  --cds-caption-01-font-weight: 400;
  --cds-caption-01-line-height: 1.33333;
  --cds-caption-01-letter-spacing: .32px;
  --cds-caption-02-font-size: .875rem;
  --cds-caption-02-font-weight: 400;
  --cds-caption-02-line-height: 1.28572;
  --cds-caption-02-letter-spacing: .32px;
  --cds-label-01-font-size: .75rem;
  --cds-label-01-font-weight: 400;
  --cds-label-01-line-height: 1.33333;
  --cds-label-01-letter-spacing: .32px;
  --cds-label-02-font-size: .875rem;
  --cds-label-02-font-weight: 400;
  --cds-label-02-line-height: 1.28572;
  --cds-label-02-letter-spacing: .16px;
  --cds-helper-text-01-font-size: .75rem;
  --cds-helper-text-01-line-height: 1.33333;
  --cds-helper-text-01-letter-spacing: .32px;
  --cds-helper-text-02-font-size: .875rem;
  --cds-helper-text-02-font-weight: 400;
  --cds-helper-text-02-line-height: 1.28572;
  --cds-helper-text-02-letter-spacing: .16px;
  --cds-body-short-01-font-size: .875rem;
  --cds-body-short-01-font-weight: 400;
  --cds-body-short-01-line-height: 1.28572;
  --cds-body-short-01-letter-spacing: .16px;
  --cds-body-short-02-font-size: 1rem;
  --cds-body-short-02-font-weight: 400;
  --cds-body-short-02-line-height: 1.375;
  --cds-body-short-02-letter-spacing: 0;
  --cds-body-long-01-font-size: .875rem;
  --cds-body-long-01-font-weight: 400;
  --cds-body-long-01-line-height: 1.42857;
  --cds-body-long-01-letter-spacing: .16px;
  --cds-body-long-02-font-size: 1rem;
  --cds-body-long-02-font-weight: 400;
  --cds-body-long-02-line-height: 1.5;
  --cds-body-long-02-letter-spacing: 0;
  --cds-code-01-font-family: "IBM Plex Mono", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", monospace;
  --cds-code-01-font-size: .75rem;
  --cds-code-01-font-weight: 400;
  --cds-code-01-line-height: 1.33333;
  --cds-code-01-letter-spacing: .32px;
  --cds-code-02-font-family: "IBM Plex Mono", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", monospace;
  --cds-code-02-font-size: .875rem;
  --cds-code-02-font-weight: 400;
  --cds-code-02-line-height: 1.42857;
  --cds-code-02-letter-spacing: .32px;
  --cds-heading-01-font-size: .875rem;
  --cds-heading-01-font-weight: 600;
  --cds-heading-01-line-height: 1.42857;
  --cds-heading-01-letter-spacing: .16px;
  --cds-heading-02-font-size: 1rem;
  --cds-heading-02-font-weight: 600;
  --cds-heading-02-line-height: 1.5;
  --cds-heading-02-letter-spacing: 0;
  --cds-productive-heading-01-font-size: .875rem;
  --cds-productive-heading-01-font-weight: 600;
  --cds-productive-heading-01-line-height: 1.28572;
  --cds-productive-heading-01-letter-spacing: .16px;
  --cds-productive-heading-02-font-size: 1rem;
  --cds-productive-heading-02-font-weight: 600;
  --cds-productive-heading-02-line-height: 1.375;
  --cds-productive-heading-02-letter-spacing: 0;
  --cds-productive-heading-03-font-size: 1.25rem;
  --cds-productive-heading-03-font-weight: 400;
  --cds-productive-heading-03-line-height: 1.4;
  --cds-productive-heading-03-letter-spacing: 0;
  --cds-productive-heading-04-font-size: 1.75rem;
  --cds-productive-heading-04-font-weight: 400;
  --cds-productive-heading-04-line-height: 1.28572;
  --cds-productive-heading-04-letter-spacing: 0;
  --cds-productive-heading-05-font-size: 2rem;
  --cds-productive-heading-05-font-weight: 400;
  --cds-productive-heading-05-line-height: 1.25;
  --cds-productive-heading-05-letter-spacing: 0;
  --cds-productive-heading-06-font-size: 2.625rem;
  --cds-productive-heading-06-font-weight: 300;
  --cds-productive-heading-06-line-height: 1.199;
  --cds-productive-heading-06-letter-spacing: 0;
  --cds-productive-heading-07-font-size: 3.375rem;
  --cds-productive-heading-07-font-weight: 300;
  --cds-productive-heading-07-line-height: 1.19;
  --cds-productive-heading-07-letter-spacing: 0;
  --cds-expressive-paragraph-01-font-size: 1.5rem;
  --cds-expressive-paragraph-01-font-weight: 300;
  --cds-expressive-paragraph-01-line-height: 1.334;
  --cds-expressive-paragraph-01-letter-spacing: 0;
  --cds-expressive-heading-01-font-size: .875rem;
  --cds-expressive-heading-01-font-weight: 600;
  --cds-expressive-heading-01-line-height: 1.42857;
  --cds-expressive-heading-01-letter-spacing: .16px;
  --cds-expressive-heading-02-font-size: 1rem;
  --cds-expressive-heading-02-font-weight: 600;
  --cds-expressive-heading-02-line-height: 1.5;
  --cds-expressive-heading-02-letter-spacing: 0;
  --cds-expressive-heading-03-font-size: 1.25rem;
  --cds-expressive-heading-03-font-weight: 400;
  --cds-expressive-heading-03-line-height: 1.4;
  --cds-expressive-heading-03-letter-spacing: 0;
  --cds-expressive-heading-04-font-size: 1.75rem;
  --cds-expressive-heading-04-font-weight: 400;
  --cds-expressive-heading-04-line-height: 1.28572;
  --cds-expressive-heading-04-letter-spacing: 0;
  --cds-expressive-heading-05-font-size: 2rem;
  --cds-expressive-heading-05-font-weight: 400;
  --cds-expressive-heading-05-line-height: 1.25;
  --cds-expressive-heading-05-letter-spacing: 0;
  --cds-expressive-heading-06-font-size: 2rem;
  --cds-expressive-heading-06-font-weight: 600;
  --cds-expressive-heading-06-line-height: 1.25;
  --cds-expressive-heading-06-letter-spacing: 0;
  --cds-quotation-01-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-quotation-01-font-size: 1.25rem;
  --cds-quotation-01-font-weight: 400;
  --cds-quotation-01-line-height: 1.3;
  --cds-quotation-01-letter-spacing: 0;
  --cds-quotation-02-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-quotation-02-font-size: 2rem;
  --cds-quotation-02-font-weight: 300;
  --cds-quotation-02-line-height: 1.25;
  --cds-quotation-02-letter-spacing: 0;
  --cds-display-01-font-size: 2.625rem;
  --cds-display-01-font-weight: 300;
  --cds-display-01-line-height: 1.19;
  --cds-display-01-letter-spacing: 0;
  --cds-display-02-font-size: 2.625rem;
  --cds-display-02-font-weight: 600;
  --cds-display-02-line-height: 1.19;
  --cds-display-02-letter-spacing: 0;
  --cds-display-03-font-size: 2.625rem;
  --cds-display-03-font-weight: 300;
  --cds-display-03-line-height: 1.19;
  --cds-display-03-letter-spacing: 0;
  --cds-display-04-font-size: 2.625rem;
  --cds-display-04-font-weight: 300;
  --cds-display-04-line-height: 1.19;
  --cds-display-04-letter-spacing: 0;
  --cds-legal-01-font-size: .75rem;
  --cds-legal-01-font-weight: 400;
  --cds-legal-01-line-height: 1.33333;
  --cds-legal-01-letter-spacing: .32px;
  --cds-legal-02-font-size: .875rem;
  --cds-legal-02-font-weight: 400;
  --cds-legal-02-line-height: 1.28572;
  --cds-legal-02-letter-spacing: .16px;
  --cds-body-compact-01-font-size: .875rem;
  --cds-body-compact-01-font-weight: 400;
  --cds-body-compact-01-line-height: 1.28572;
  --cds-body-compact-01-letter-spacing: .16px;
  --cds-body-compact-02-font-size: 1rem;
  --cds-body-compact-02-font-weight: 400;
  --cds-body-compact-02-line-height: 1.375;
  --cds-body-compact-02-letter-spacing: 0;
  --cds-heading-compact-01-font-size: .875rem;
  --cds-heading-compact-01-font-weight: 600;
  --cds-heading-compact-01-line-height: 1.28572;
  --cds-heading-compact-01-letter-spacing: .16px;
  --cds-heading-compact-02-font-size: 1rem;
  --cds-heading-compact-02-font-weight: 600;
  --cds-heading-compact-02-line-height: 1.375;
  --cds-heading-compact-02-letter-spacing: 0;
  --cds-body-01-font-size: .875rem;
  --cds-body-01-font-weight: 400;
  --cds-body-01-line-height: 1.42857;
  --cds-body-01-letter-spacing: .16px;
  --cds-body-02-font-size: 1rem;
  --cds-body-02-font-weight: 400;
  --cds-body-02-line-height: 1.5;
  --cds-body-02-letter-spacing: 0;
  --cds-heading-03-font-size: 1.25rem;
  --cds-heading-03-font-weight: 400;
  --cds-heading-03-line-height: 1.4;
  --cds-heading-03-letter-spacing: 0;
  --cds-heading-04-font-size: 1.75rem;
  --cds-heading-04-font-weight: 400;
  --cds-heading-04-line-height: 1.28572;
  --cds-heading-04-letter-spacing: 0;
  --cds-heading-05-font-size: 2rem;
  --cds-heading-05-font-weight: 400;
  --cds-heading-05-line-height: 1.25;
  --cds-heading-05-letter-spacing: 0;
  --cds-heading-06-font-size: 2.625rem;
  --cds-heading-06-font-weight: 300;
  --cds-heading-06-line-height: 1.199;
  --cds-heading-06-letter-spacing: 0;
  --cds-heading-07-font-size: 3.375rem;
  --cds-heading-07-font-weight: 300;
  --cds-heading-07-line-height: 1.19;
  --cds-heading-07-letter-spacing: 0;
  --cds-fluid-heading-03-font-size: 1.25rem;
  --cds-fluid-heading-03-font-weight: 400;
  --cds-fluid-heading-03-line-height: 1.4;
  --cds-fluid-heading-03-letter-spacing: 0;
  --cds-fluid-heading-04-font-size: 1.75rem;
  --cds-fluid-heading-04-font-weight: 400;
  --cds-fluid-heading-04-line-height: 1.28572;
  --cds-fluid-heading-04-letter-spacing: 0;
  --cds-fluid-heading-05-font-size: 2rem;
  --cds-fluid-heading-05-font-weight: 400;
  --cds-fluid-heading-05-line-height: 1.25;
  --cds-fluid-heading-05-letter-spacing: 0;
  --cds-fluid-heading-06-font-size: 2rem;
  --cds-fluid-heading-06-font-weight: 600;
  --cds-fluid-heading-06-line-height: 1.25;
  --cds-fluid-heading-06-letter-spacing: 0;
  --cds-fluid-paragraph-01-font-size: 1.5rem;
  --cds-fluid-paragraph-01-font-weight: 300;
  --cds-fluid-paragraph-01-line-height: 1.334;
  --cds-fluid-paragraph-01-letter-spacing: 0;
  --cds-fluid-quotation-01-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-fluid-quotation-01-font-size: 1.25rem;
  --cds-fluid-quotation-01-font-weight: 400;
  --cds-fluid-quotation-01-line-height: 1.3;
  --cds-fluid-quotation-01-letter-spacing: 0;
  --cds-fluid-quotation-02-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-fluid-quotation-02-font-size: 2rem;
  --cds-fluid-quotation-02-font-weight: 300;
  --cds-fluid-quotation-02-line-height: 1.25;
  --cds-fluid-quotation-02-letter-spacing: 0;
  --cds-fluid-display-01-font-size: 2.625rem;
  --cds-fluid-display-01-font-weight: 300;
  --cds-fluid-display-01-line-height: 1.19;
  --cds-fluid-display-01-letter-spacing: 0;
  --cds-fluid-display-02-font-size: 2.625rem;
  --cds-fluid-display-02-font-weight: 600;
  --cds-fluid-display-02-line-height: 1.19;
  --cds-fluid-display-02-letter-spacing: 0;
  --cds-fluid-display-03-font-size: 2.625rem;
  --cds-fluid-display-03-font-weight: 300;
  --cds-fluid-display-03-line-height: 1.19;
  --cds-fluid-display-03-letter-spacing: 0;
  --cds-fluid-display-04-font-size: 2.625rem;
  --cds-fluid-display-04-font-weight: 300;
  --cds-fluid-display-04-line-height: 1.19;
  --cds-fluid-display-04-letter-spacing: 0;
  color: var(--cds-text-primary, #161616);
  background: var(--cds-background, white);
}

.cds-theme-zone-g100 {
  --cds-ai-aura-end: #0000;
  --cds-ai-aura-hover-background: #333;
  --cds-ai-aura-hover-end: #0000;
  --cds-ai-aura-hover-start: #4589ff66;
  --cds-ai-aura-start: #4589ff1a;
  --cds-ai-aura-start-sm: #4589ff29;
  --cds-ai-border-end: #4589ff;
  --cds-ai-border-start: #a6c8ff5c;
  --cds-ai-border-strong: #78a9ff;
  --cds-ai-drop-shadow: #00000047;
  --cds-ai-inner-shadow: #4589ff29;
  --cds-ai-overlay: #00000080;
  --cds-ai-popover-background: #161616;
  --cds-ai-popover-caret-bottom: #4589ff;
  --cds-ai-popover-caret-bottom-background: #202d45;
  --cds-ai-popover-caret-bottom-background-actions: #1e283a;
  --cds-ai-popover-caret-center: #4870b5;
  --cds-ai-popover-shadow-outer-01: #0000001f;
  --cds-ai-popover-shadow-outer-02: #00000014;
  --cds-ai-skeleton-background: #78a9ff80;
  --cds-ai-skeleton-element-background: #78a9ff4d;
  --cds-background: #161616;
  --cds-background-active: #8d8d8d66;
  --cds-background-brand: #0f62fe;
  --cds-background-hover: #8d8d8d29;
  --cds-background-inverse: #f4f4f4;
  --cds-background-inverse-hover: #e8e8e8;
  --cds-background-selected: #8d8d8d3d;
  --cds-background-selected-hover: #8d8d8d52;
  --cds-border-disabled: #8d8d8d80;
  --cds-border-interactive: #4589ff;
  --cds-border-inverse: #f4f4f4;
  --cds-border-strong-01: #6f6f6f;
  --cds-border-strong-02: #8d8d8d;
  --cds-border-strong-03: #a8a8a8;
  --cds-border-subtle-00: #393939;
  --cds-border-subtle-01: #525252;
  --cds-border-subtle-02: #6f6f6f;
  --cds-border-subtle-03: #6f6f6f;
  --cds-border-subtle-selected-01: #6f6f6f;
  --cds-border-subtle-selected-02: #8d8d8d;
  --cds-border-subtle-selected-03: #8d8d8d;
  --cds-border-tile-01: #525252;
  --cds-border-tile-02: #6f6f6f;
  --cds-border-tile-03: #8d8d8d;
  --cds-chat-avatar-agent: #c6c6c6;
  --cds-chat-avatar-bot: #8d8d8d;
  --cds-chat-avatar-user: #4589ff;
  --cds-chat-bubble-agent: #262626;
  --cds-chat-bubble-border: #525252;
  --cds-chat-bubble-user: #393939;
  --cds-chat-button: #78a9ff;
  --cds-chat-button-active: #8d8d8d66;
  --cds-chat-button-hover: #8d8d8d29;
  --cds-chat-button-selected: #8d8d8d3d;
  --cds-chat-button-text-hover: #a6c8ff;
  --cds-chat-button-text-selected: #c6c6c6;
  --cds-chat-header-background: #262626;
  --cds-chat-prompt-background: #161616;
  --cds-chat-prompt-border-end: #26262600;
  --cds-chat-prompt-border-start: #262626;
  --cds-chat-shell-background: #262626;
  --cds-field-01: #262626;
  --cds-field-02: #393939;
  --cds-field-03: #525252;
  --cds-field-hover-01: #333;
  --cds-field-hover-02: #474747;
  --cds-field-hover-03: #636363;
  --cds-focus: #fff;
  --cds-focus-inset: #161616;
  --cds-focus-inverse: #0f62fe;
  --cds-highlight: #002d9c;
  --cds-icon-disabled: #f4f4f440;
  --cds-icon-interactive: #fff;
  --cds-icon-inverse: #161616;
  --cds-icon-on-color: #fff;
  --cds-icon-on-color-disabled: #ffffff40;
  --cds-icon-primary: #f4f4f4;
  --cds-icon-secondary: #c6c6c6;
  --cds-interactive: #4589ff;
  --cds-layer-01: #262626;
  --cds-layer-02: #393939;
  --cds-layer-03: #525252;
  --cds-layer-accent-01: #393939;
  --cds-layer-accent-02: #525252;
  --cds-layer-accent-03: #6f6f6f;
  --cds-layer-accent-active-01: #6f6f6f;
  --cds-layer-accent-active-02: #8d8d8d;
  --cds-layer-accent-active-03: #393939;
  --cds-layer-accent-hover-01: #474747;
  --cds-layer-accent-hover-02: #636363;
  --cds-layer-accent-hover-03: #5e5e5e;
  --cds-layer-active-01: #525252;
  --cds-layer-active-02: #6f6f6f;
  --cds-layer-active-03: #8d8d8d;
  --cds-layer-hover-01: #333;
  --cds-layer-hover-02: #474747;
  --cds-layer-hover-03: #636363;
  --cds-layer-selected-01: #393939;
  --cds-layer-selected-02: #525252;
  --cds-layer-selected-03: #6f6f6f;
  --cds-layer-selected-disabled: #a8a8a8;
  --cds-layer-selected-hover-01: #474747;
  --cds-layer-selected-hover-02: #636363;
  --cds-layer-selected-hover-03: #5e5e5e;
  --cds-layer-selected-inverse: #f4f4f4;
  --cds-link-inverse: #0f62fe;
  --cds-link-inverse-active: #161616;
  --cds-link-inverse-hover: #0043ce;
  --cds-link-inverse-visited: #8a3ffc;
  --cds-link-primary: #78a9ff;
  --cds-link-primary-hover: #a6c8ff;
  --cds-link-secondary: #a6c8ff;
  --cds-link-visited: #be95ff;
  --cds-overlay: #000000a6;
  --cds-shadow: #000c;
  --cds-skeleton-background: #292929;
  --cds-skeleton-element: #393939;
  --cds-support-caution-major: #ff832b;
  --cds-support-caution-minor: #f1c21b;
  --cds-support-caution-undefined: #a56eff;
  --cds-support-error: #fa4d56;
  --cds-support-error-inverse: #da1e28;
  --cds-support-info: #4589ff;
  --cds-support-info-inverse: #0043ce;
  --cds-support-success: #42be65;
  --cds-support-success-inverse: #24a148;
  --cds-support-warning: #f1c21b;
  --cds-support-warning-inverse: #f1c21b;
  --cds-text-disabled: #f4f4f440;
  --cds-text-error: #ff8389;
  --cds-text-helper: #a8a8a8;
  --cds-text-inverse: #161616;
  --cds-text-on-color: #fff;
  --cds-text-on-color-disabled: #ffffff40;
  --cds-text-placeholder: #f4f4f466;
  --cds-text-primary: #f4f4f4;
  --cds-text-secondary: #c6c6c6;
  --cds-toggle-off: #6f6f6f;
  --cds-spacing-01: .125rem;
  --cds-spacing-02: .25rem;
  --cds-spacing-03: .5rem;
  --cds-spacing-04: .75rem;
  --cds-spacing-05: 1rem;
  --cds-spacing-06: 1.5rem;
  --cds-spacing-07: 2rem;
  --cds-spacing-08: 2.5rem;
  --cds-spacing-09: 3rem;
  --cds-spacing-10: 4rem;
  --cds-spacing-11: 5rem;
  --cds-spacing-12: 6rem;
  --cds-spacing-13: 10rem;
  --cds-fluid-spacing-01: 0;
  --cds-fluid-spacing-02: 2vw;
  --cds-fluid-spacing-03: 5vw;
  --cds-fluid-spacing-04: 10vw;
  --cds-caption-01-font-size: .75rem;
  --cds-caption-01-font-weight: 400;
  --cds-caption-01-line-height: 1.33333;
  --cds-caption-01-letter-spacing: .32px;
  --cds-caption-02-font-size: .875rem;
  --cds-caption-02-font-weight: 400;
  --cds-caption-02-line-height: 1.28572;
  --cds-caption-02-letter-spacing: .32px;
  --cds-label-01-font-size: .75rem;
  --cds-label-01-font-weight: 400;
  --cds-label-01-line-height: 1.33333;
  --cds-label-01-letter-spacing: .32px;
  --cds-label-02-font-size: .875rem;
  --cds-label-02-font-weight: 400;
  --cds-label-02-line-height: 1.28572;
  --cds-label-02-letter-spacing: .16px;
  --cds-helper-text-01-font-size: .75rem;
  --cds-helper-text-01-line-height: 1.33333;
  --cds-helper-text-01-letter-spacing: .32px;
  --cds-helper-text-02-font-size: .875rem;
  --cds-helper-text-02-font-weight: 400;
  --cds-helper-text-02-line-height: 1.28572;
  --cds-helper-text-02-letter-spacing: .16px;
  --cds-body-short-01-font-size: .875rem;
  --cds-body-short-01-font-weight: 400;
  --cds-body-short-01-line-height: 1.28572;
  --cds-body-short-01-letter-spacing: .16px;
  --cds-body-short-02-font-size: 1rem;
  --cds-body-short-02-font-weight: 400;
  --cds-body-short-02-line-height: 1.375;
  --cds-body-short-02-letter-spacing: 0;
  --cds-body-long-01-font-size: .875rem;
  --cds-body-long-01-font-weight: 400;
  --cds-body-long-01-line-height: 1.42857;
  --cds-body-long-01-letter-spacing: .16px;
  --cds-body-long-02-font-size: 1rem;
  --cds-body-long-02-font-weight: 400;
  --cds-body-long-02-line-height: 1.5;
  --cds-body-long-02-letter-spacing: 0;
  --cds-code-01-font-family: "IBM Plex Mono", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", monospace;
  --cds-code-01-font-size: .75rem;
  --cds-code-01-font-weight: 400;
  --cds-code-01-line-height: 1.33333;
  --cds-code-01-letter-spacing: .32px;
  --cds-code-02-font-family: "IBM Plex Mono", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", monospace;
  --cds-code-02-font-size: .875rem;
  --cds-code-02-font-weight: 400;
  --cds-code-02-line-height: 1.42857;
  --cds-code-02-letter-spacing: .32px;
  --cds-heading-01-font-size: .875rem;
  --cds-heading-01-font-weight: 600;
  --cds-heading-01-line-height: 1.42857;
  --cds-heading-01-letter-spacing: .16px;
  --cds-heading-02-font-size: 1rem;
  --cds-heading-02-font-weight: 600;
  --cds-heading-02-line-height: 1.5;
  --cds-heading-02-letter-spacing: 0;
  --cds-productive-heading-01-font-size: .875rem;
  --cds-productive-heading-01-font-weight: 600;
  --cds-productive-heading-01-line-height: 1.28572;
  --cds-productive-heading-01-letter-spacing: .16px;
  --cds-productive-heading-02-font-size: 1rem;
  --cds-productive-heading-02-font-weight: 600;
  --cds-productive-heading-02-line-height: 1.375;
  --cds-productive-heading-02-letter-spacing: 0;
  --cds-productive-heading-03-font-size: 1.25rem;
  --cds-productive-heading-03-font-weight: 400;
  --cds-productive-heading-03-line-height: 1.4;
  --cds-productive-heading-03-letter-spacing: 0;
  --cds-productive-heading-04-font-size: 1.75rem;
  --cds-productive-heading-04-font-weight: 400;
  --cds-productive-heading-04-line-height: 1.28572;
  --cds-productive-heading-04-letter-spacing: 0;
  --cds-productive-heading-05-font-size: 2rem;
  --cds-productive-heading-05-font-weight: 400;
  --cds-productive-heading-05-line-height: 1.25;
  --cds-productive-heading-05-letter-spacing: 0;
  --cds-productive-heading-06-font-size: 2.625rem;
  --cds-productive-heading-06-font-weight: 300;
  --cds-productive-heading-06-line-height: 1.199;
  --cds-productive-heading-06-letter-spacing: 0;
  --cds-productive-heading-07-font-size: 3.375rem;
  --cds-productive-heading-07-font-weight: 300;
  --cds-productive-heading-07-line-height: 1.19;
  --cds-productive-heading-07-letter-spacing: 0;
  --cds-expressive-paragraph-01-font-size: 1.5rem;
  --cds-expressive-paragraph-01-font-weight: 300;
  --cds-expressive-paragraph-01-line-height: 1.334;
  --cds-expressive-paragraph-01-letter-spacing: 0;
  --cds-expressive-heading-01-font-size: .875rem;
  --cds-expressive-heading-01-font-weight: 600;
  --cds-expressive-heading-01-line-height: 1.42857;
  --cds-expressive-heading-01-letter-spacing: .16px;
  --cds-expressive-heading-02-font-size: 1rem;
  --cds-expressive-heading-02-font-weight: 600;
  --cds-expressive-heading-02-line-height: 1.5;
  --cds-expressive-heading-02-letter-spacing: 0;
  --cds-expressive-heading-03-font-size: 1.25rem;
  --cds-expressive-heading-03-font-weight: 400;
  --cds-expressive-heading-03-line-height: 1.4;
  --cds-expressive-heading-03-letter-spacing: 0;
  --cds-expressive-heading-04-font-size: 1.75rem;
  --cds-expressive-heading-04-font-weight: 400;
  --cds-expressive-heading-04-line-height: 1.28572;
  --cds-expressive-heading-04-letter-spacing: 0;
  --cds-expressive-heading-05-font-size: 2rem;
  --cds-expressive-heading-05-font-weight: 400;
  --cds-expressive-heading-05-line-height: 1.25;
  --cds-expressive-heading-05-letter-spacing: 0;
  --cds-expressive-heading-06-font-size: 2rem;
  --cds-expressive-heading-06-font-weight: 600;
  --cds-expressive-heading-06-line-height: 1.25;
  --cds-expressive-heading-06-letter-spacing: 0;
  --cds-quotation-01-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-quotation-01-font-size: 1.25rem;
  --cds-quotation-01-font-weight: 400;
  --cds-quotation-01-line-height: 1.3;
  --cds-quotation-01-letter-spacing: 0;
  --cds-quotation-02-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-quotation-02-font-size: 2rem;
  --cds-quotation-02-font-weight: 300;
  --cds-quotation-02-line-height: 1.25;
  --cds-quotation-02-letter-spacing: 0;
  --cds-display-01-font-size: 2.625rem;
  --cds-display-01-font-weight: 300;
  --cds-display-01-line-height: 1.19;
  --cds-display-01-letter-spacing: 0;
  --cds-display-02-font-size: 2.625rem;
  --cds-display-02-font-weight: 600;
  --cds-display-02-line-height: 1.19;
  --cds-display-02-letter-spacing: 0;
  --cds-display-03-font-size: 2.625rem;
  --cds-display-03-font-weight: 300;
  --cds-display-03-line-height: 1.19;
  --cds-display-03-letter-spacing: 0;
  --cds-display-04-font-size: 2.625rem;
  --cds-display-04-font-weight: 300;
  --cds-display-04-line-height: 1.19;
  --cds-display-04-letter-spacing: 0;
  --cds-legal-01-font-size: .75rem;
  --cds-legal-01-font-weight: 400;
  --cds-legal-01-line-height: 1.33333;
  --cds-legal-01-letter-spacing: .32px;
  --cds-legal-02-font-size: .875rem;
  --cds-legal-02-font-weight: 400;
  --cds-legal-02-line-height: 1.28572;
  --cds-legal-02-letter-spacing: .16px;
  --cds-body-compact-01-font-size: .875rem;
  --cds-body-compact-01-font-weight: 400;
  --cds-body-compact-01-line-height: 1.28572;
  --cds-body-compact-01-letter-spacing: .16px;
  --cds-body-compact-02-font-size: 1rem;
  --cds-body-compact-02-font-weight: 400;
  --cds-body-compact-02-line-height: 1.375;
  --cds-body-compact-02-letter-spacing: 0;
  --cds-heading-compact-01-font-size: .875rem;
  --cds-heading-compact-01-font-weight: 600;
  --cds-heading-compact-01-line-height: 1.28572;
  --cds-heading-compact-01-letter-spacing: .16px;
  --cds-heading-compact-02-font-size: 1rem;
  --cds-heading-compact-02-font-weight: 600;
  --cds-heading-compact-02-line-height: 1.375;
  --cds-heading-compact-02-letter-spacing: 0;
  --cds-body-01-font-size: .875rem;
  --cds-body-01-font-weight: 400;
  --cds-body-01-line-height: 1.42857;
  --cds-body-01-letter-spacing: .16px;
  --cds-body-02-font-size: 1rem;
  --cds-body-02-font-weight: 400;
  --cds-body-02-line-height: 1.5;
  --cds-body-02-letter-spacing: 0;
  --cds-heading-03-font-size: 1.25rem;
  --cds-heading-03-font-weight: 400;
  --cds-heading-03-line-height: 1.4;
  --cds-heading-03-letter-spacing: 0;
  --cds-heading-04-font-size: 1.75rem;
  --cds-heading-04-font-weight: 400;
  --cds-heading-04-line-height: 1.28572;
  --cds-heading-04-letter-spacing: 0;
  --cds-heading-05-font-size: 2rem;
  --cds-heading-05-font-weight: 400;
  --cds-heading-05-line-height: 1.25;
  --cds-heading-05-letter-spacing: 0;
  --cds-heading-06-font-size: 2.625rem;
  --cds-heading-06-font-weight: 300;
  --cds-heading-06-line-height: 1.199;
  --cds-heading-06-letter-spacing: 0;
  --cds-heading-07-font-size: 3.375rem;
  --cds-heading-07-font-weight: 300;
  --cds-heading-07-line-height: 1.19;
  --cds-heading-07-letter-spacing: 0;
  --cds-fluid-heading-03-font-size: 1.25rem;
  --cds-fluid-heading-03-font-weight: 400;
  --cds-fluid-heading-03-line-height: 1.4;
  --cds-fluid-heading-03-letter-spacing: 0;
  --cds-fluid-heading-04-font-size: 1.75rem;
  --cds-fluid-heading-04-font-weight: 400;
  --cds-fluid-heading-04-line-height: 1.28572;
  --cds-fluid-heading-04-letter-spacing: 0;
  --cds-fluid-heading-05-font-size: 2rem;
  --cds-fluid-heading-05-font-weight: 400;
  --cds-fluid-heading-05-line-height: 1.25;
  --cds-fluid-heading-05-letter-spacing: 0;
  --cds-fluid-heading-06-font-size: 2rem;
  --cds-fluid-heading-06-font-weight: 600;
  --cds-fluid-heading-06-line-height: 1.25;
  --cds-fluid-heading-06-letter-spacing: 0;
  --cds-fluid-paragraph-01-font-size: 1.5rem;
  --cds-fluid-paragraph-01-font-weight: 300;
  --cds-fluid-paragraph-01-line-height: 1.334;
  --cds-fluid-paragraph-01-letter-spacing: 0;
  --cds-fluid-quotation-01-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-fluid-quotation-01-font-size: 1.25rem;
  --cds-fluid-quotation-01-font-weight: 400;
  --cds-fluid-quotation-01-line-height: 1.3;
  --cds-fluid-quotation-01-letter-spacing: 0;
  --cds-fluid-quotation-02-font-family: "IBM Plex Serif", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", serif;
  --cds-fluid-quotation-02-font-size: 2rem;
  --cds-fluid-quotation-02-font-weight: 300;
  --cds-fluid-quotation-02-line-height: 1.25;
  --cds-fluid-quotation-02-letter-spacing: 0;
  --cds-fluid-display-01-font-size: 2.625rem;
  --cds-fluid-display-01-font-weight: 300;
  --cds-fluid-display-01-line-height: 1.19;
  --cds-fluid-display-01-letter-spacing: 0;
  --cds-fluid-display-02-font-size: 2.625rem;
  --cds-fluid-display-02-font-weight: 600;
  --cds-fluid-display-02-line-height: 1.19;
  --cds-fluid-display-02-letter-spacing: 0;
  --cds-fluid-display-03-font-size: 2.625rem;
  --cds-fluid-display-03-font-weight: 300;
  --cds-fluid-display-03-line-height: 1.19;
  --cds-fluid-display-03-letter-spacing: 0;
  --cds-fluid-display-04-font-size: 2.625rem;
  --cds-fluid-display-04-font-weight: 300;
  --cds-fluid-display-04-line-height: 1.19;
  --cds-fluid-display-04-letter-spacing: 0;
  color: var(--cds-text-primary, #161616);
  background: var(--cds-background, white);
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

button, select, input, textarea {
  border-radius: 0;
  font-family: inherit;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  background-color: var(--cds-background, #fff);
  color: var(--cds-text-primary, #161616);
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

@media screen and (-ms-high-contrast: active) {
  svg {
    fill: buttontext;
  }
}

.cds--grid {
  max-inline-size: 99rem;
  margin-inline: auto;
  padding-inline: 1rem;
}

@media (width >= 42rem) {
  .cds--grid {
    padding-inline: 2rem;
  }
}

@media (width >= 99rem) {
  .cds--grid {
    padding-inline: 2.5rem;
  }

  .cds--grid--full-width {
    max-inline-size: 100%;
  }
}

.cds--row {
  flex-wrap: wrap;
  margin-inline: -1rem;
  display: flex;
}

.cds--row-padding [class*="cds--col"], .cds--col-padding {
  padding-block: 1rem;
}

.cds--grid--condensed [class*="cds--col"] {
  padding-block: .03125rem;
}

.cds--col {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col, .cds--grid--condensed .cds--col {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col, .cds--grid--narrow .cds--col {
  padding-inline: 0 1rem;
}

.cds--col-sm-0 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-sm-0, .cds--grid--condensed .cds--col-sm-0 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-sm-0, .cds--grid--narrow .cds--col-sm-0 {
  padding-inline: 0 1rem;
}

.cds--col-sm-1 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-sm-1, .cds--grid--condensed .cds--col-sm-1 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-sm-1, .cds--grid--narrow .cds--col-sm-1 {
  padding-inline: 0 1rem;
}

.cds--col-sm-2 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-sm-2, .cds--grid--condensed .cds--col-sm-2 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-sm-2, .cds--grid--narrow .cds--col-sm-2 {
  padding-inline: 0 1rem;
}

.cds--col-sm-3 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-sm-3, .cds--grid--condensed .cds--col-sm-3 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-sm-3, .cds--grid--narrow .cds--col-sm-3 {
  padding-inline: 0 1rem;
}

.cds--col-sm-4 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-sm-4, .cds--grid--condensed .cds--col-sm-4 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-sm-4, .cds--grid--narrow .cds--col-sm-4 {
  padding-inline: 0 1rem;
}

.cds--col-sm, .cds--col-sm--auto {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-sm, .cds--grid--condensed .cds--col-sm, .cds--row--condensed .cds--col-sm--auto, .cds--grid--condensed .cds--col-sm--auto {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-sm, .cds--grid--narrow .cds--col-sm, .cds--row--narrow .cds--col-sm--auto, .cds--grid--narrow .cds--col-sm--auto {
  padding-inline: 0 1rem;
}

.cds--col, .cds--col-sm {
  flex-grow: 1;
  flex-basis: 0;
  max-inline-size: 100%;
}

.cds--col--auto, .cds--col-sm--auto {
  flex: 1 0;
  inline-size: auto;
  max-inline-size: 100%;
}

.cds--col-sm-0 {
  display: none;
}

.cds--col-sm-1 {
  flex: 0 0 25%;
  max-inline-size: 25%;
  display: block;
}

.cds--col-sm-2 {
  flex: 0 0 50%;
  max-inline-size: 50%;
  display: block;
}

.cds--col-sm-3 {
  flex: 0 0 75%;
  max-inline-size: 75%;
  display: block;
}

.cds--col-sm-4 {
  flex: 0 0 100%;
  max-inline-size: 100%;
  display: block;
}

.cds--offset-sm-0 {
  margin-inline-start: 0;
}

.cds--offset-sm-1 {
  margin-inline-start: 25%;
}

.cds--offset-sm-2 {
  margin-inline-start: 50%;
}

.cds--offset-sm-3 {
  margin-inline-start: 75%;
}

.cds--col-md-0 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-md-0, .cds--grid--condensed .cds--col-md-0 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-md-0, .cds--grid--narrow .cds--col-md-0 {
  padding-inline: 0 1rem;
}

.cds--col-md-1 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-md-1, .cds--grid--condensed .cds--col-md-1 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-md-1, .cds--grid--narrow .cds--col-md-1 {
  padding-inline: 0 1rem;
}

.cds--col-md-2 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-md-2, .cds--grid--condensed .cds--col-md-2 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-md-2, .cds--grid--narrow .cds--col-md-2 {
  padding-inline: 0 1rem;
}

.cds--col-md-3 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-md-3, .cds--grid--condensed .cds--col-md-3 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-md-3, .cds--grid--narrow .cds--col-md-3 {
  padding-inline: 0 1rem;
}

.cds--col-md-4 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-md-4, .cds--grid--condensed .cds--col-md-4 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-md-4, .cds--grid--narrow .cds--col-md-4 {
  padding-inline: 0 1rem;
}

.cds--col-md-5 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-md-5, .cds--grid--condensed .cds--col-md-5 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-md-5, .cds--grid--narrow .cds--col-md-5 {
  padding-inline: 0 1rem;
}

.cds--col-md-6 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-md-6, .cds--grid--condensed .cds--col-md-6 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-md-6, .cds--grid--narrow .cds--col-md-6 {
  padding-inline: 0 1rem;
}

.cds--col-md-7 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-md-7, .cds--grid--condensed .cds--col-md-7 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-md-7, .cds--grid--narrow .cds--col-md-7 {
  padding-inline: 0 1rem;
}

.cds--col-md-8 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-md-8, .cds--grid--condensed .cds--col-md-8 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-md-8, .cds--grid--narrow .cds--col-md-8 {
  padding-inline: 0 1rem;
}

.cds--col-md, .cds--col-md--auto {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-md, .cds--grid--condensed .cds--col-md, .cds--row--condensed .cds--col-md--auto, .cds--grid--condensed .cds--col-md--auto {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-md, .cds--grid--narrow .cds--col-md, .cds--row--narrow .cds--col-md--auto, .cds--grid--narrow .cds--col-md--auto {
  padding-inline: 0 1rem;
}

@media (width >= 42rem) {
  .cds--col, .cds--col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-inline-size: 100%;
  }

  .cds--col--auto, .cds--col-md--auto {
    flex: 1 0;
    inline-size: auto;
    max-inline-size: 100%;
  }

  .cds--col-md-0 {
    display: none;
  }

  .cds--col-md-1 {
    flex: 0 0 12.5%;
    max-inline-size: 12.5%;
    display: block;
  }

  .cds--col-md-2 {
    flex: 0 0 25%;
    max-inline-size: 25%;
    display: block;
  }

  .cds--col-md-3 {
    flex: 0 0 37.5%;
    max-inline-size: 37.5%;
    display: block;
  }

  .cds--col-md-4 {
    flex: 0 0 50%;
    max-inline-size: 50%;
    display: block;
  }

  .cds--col-md-5 {
    flex: 0 0 62.5%;
    max-inline-size: 62.5%;
    display: block;
  }

  .cds--col-md-6 {
    flex: 0 0 75%;
    max-inline-size: 75%;
    display: block;
  }

  .cds--col-md-7 {
    flex: 0 0 87.5%;
    max-inline-size: 87.5%;
    display: block;
  }

  .cds--col-md-8 {
    flex: 0 0 100%;
    max-inline-size: 100%;
    display: block;
  }

  .cds--offset-md-0 {
    margin-inline-start: 0;
  }

  .cds--offset-md-1 {
    margin-inline-start: 12.5%;
  }

  .cds--offset-md-2 {
    margin-inline-start: 25%;
  }

  .cds--offset-md-3 {
    margin-inline-start: 37.5%;
  }

  .cds--offset-md-4 {
    margin-inline-start: 50%;
  }

  .cds--offset-md-5 {
    margin-inline-start: 62.5%;
  }

  .cds--offset-md-6 {
    margin-inline-start: 75%;
  }

  .cds--offset-md-7 {
    margin-inline-start: 87.5%;
  }
}

.cds--col-lg-0 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-0, .cds--grid--condensed .cds--col-lg-0 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-0, .cds--grid--narrow .cds--col-lg-0 {
  padding-inline: 0 1rem;
}

.cds--col-lg-1 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-1, .cds--grid--condensed .cds--col-lg-1 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-1, .cds--grid--narrow .cds--col-lg-1 {
  padding-inline: 0 1rem;
}

.cds--col-lg-2 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-2, .cds--grid--condensed .cds--col-lg-2 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-2, .cds--grid--narrow .cds--col-lg-2 {
  padding-inline: 0 1rem;
}

.cds--col-lg-3 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-3, .cds--grid--condensed .cds--col-lg-3 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-3, .cds--grid--narrow .cds--col-lg-3 {
  padding-inline: 0 1rem;
}

.cds--col-lg-4 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-4, .cds--grid--condensed .cds--col-lg-4 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-4, .cds--grid--narrow .cds--col-lg-4 {
  padding-inline: 0 1rem;
}

.cds--col-lg-5 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-5, .cds--grid--condensed .cds--col-lg-5 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-5, .cds--grid--narrow .cds--col-lg-5 {
  padding-inline: 0 1rem;
}

.cds--col-lg-6 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-6, .cds--grid--condensed .cds--col-lg-6 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-6, .cds--grid--narrow .cds--col-lg-6 {
  padding-inline: 0 1rem;
}

.cds--col-lg-7 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-7, .cds--grid--condensed .cds--col-lg-7 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-7, .cds--grid--narrow .cds--col-lg-7 {
  padding-inline: 0 1rem;
}

.cds--col-lg-8 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-8, .cds--grid--condensed .cds--col-lg-8 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-8, .cds--grid--narrow .cds--col-lg-8 {
  padding-inline: 0 1rem;
}

.cds--col-lg-9 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-9, .cds--grid--condensed .cds--col-lg-9 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-9, .cds--grid--narrow .cds--col-lg-9 {
  padding-inline: 0 1rem;
}

.cds--col-lg-10 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-10, .cds--grid--condensed .cds--col-lg-10 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-10, .cds--grid--narrow .cds--col-lg-10 {
  padding-inline: 0 1rem;
}

.cds--col-lg-11 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-11, .cds--grid--condensed .cds--col-lg-11 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-11, .cds--grid--narrow .cds--col-lg-11 {
  padding-inline: 0 1rem;
}

.cds--col-lg-12 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-12, .cds--grid--condensed .cds--col-lg-12 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-12, .cds--grid--narrow .cds--col-lg-12 {
  padding-inline: 0 1rem;
}

.cds--col-lg-13 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-13, .cds--grid--condensed .cds--col-lg-13 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-13, .cds--grid--narrow .cds--col-lg-13 {
  padding-inline: 0 1rem;
}

.cds--col-lg-14 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-14, .cds--grid--condensed .cds--col-lg-14 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-14, .cds--grid--narrow .cds--col-lg-14 {
  padding-inline: 0 1rem;
}

.cds--col-lg-15 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-15, .cds--grid--condensed .cds--col-lg-15 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-15, .cds--grid--narrow .cds--col-lg-15 {
  padding-inline: 0 1rem;
}

.cds--col-lg-16 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg-16, .cds--grid--condensed .cds--col-lg-16 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg-16, .cds--grid--narrow .cds--col-lg-16 {
  padding-inline: 0 1rem;
}

.cds--col-lg, .cds--col-lg--auto {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-lg, .cds--grid--condensed .cds--col-lg, .cds--row--condensed .cds--col-lg--auto, .cds--grid--condensed .cds--col-lg--auto {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-lg, .cds--grid--narrow .cds--col-lg, .cds--row--narrow .cds--col-lg--auto, .cds--grid--narrow .cds--col-lg--auto {
  padding-inline: 0 1rem;
}

@media (width >= 66rem) {
  .cds--col, .cds--col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-inline-size: 100%;
  }

  .cds--col--auto, .cds--col-lg--auto {
    flex: 1 0;
    inline-size: auto;
    max-inline-size: 100%;
  }

  .cds--col-lg-0 {
    display: none;
  }

  .cds--col-lg-1 {
    flex: 0 0 6.25%;
    max-inline-size: 6.25%;
    display: block;
  }

  .cds--col-lg-2 {
    flex: 0 0 12.5%;
    max-inline-size: 12.5%;
    display: block;
  }

  .cds--col-lg-3 {
    flex: 0 0 18.75%;
    max-inline-size: 18.75%;
    display: block;
  }

  .cds--col-lg-4 {
    flex: 0 0 25%;
    max-inline-size: 25%;
    display: block;
  }

  .cds--col-lg-5 {
    flex: 0 0 31.25%;
    max-inline-size: 31.25%;
    display: block;
  }

  .cds--col-lg-6 {
    flex: 0 0 37.5%;
    max-inline-size: 37.5%;
    display: block;
  }

  .cds--col-lg-7 {
    flex: 0 0 43.75%;
    max-inline-size: 43.75%;
    display: block;
  }

  .cds--col-lg-8 {
    flex: 0 0 50%;
    max-inline-size: 50%;
    display: block;
  }

  .cds--col-lg-9 {
    flex: 0 0 56.25%;
    max-inline-size: 56.25%;
    display: block;
  }

  .cds--col-lg-10 {
    flex: 0 0 62.5%;
    max-inline-size: 62.5%;
    display: block;
  }

  .cds--col-lg-11 {
    flex: 0 0 68.75%;
    max-inline-size: 68.75%;
    display: block;
  }

  .cds--col-lg-12 {
    flex: 0 0 75%;
    max-inline-size: 75%;
    display: block;
  }

  .cds--col-lg-13 {
    flex: 0 0 81.25%;
    max-inline-size: 81.25%;
    display: block;
  }

  .cds--col-lg-14 {
    flex: 0 0 87.5%;
    max-inline-size: 87.5%;
    display: block;
  }

  .cds--col-lg-15 {
    flex: 0 0 93.75%;
    max-inline-size: 93.75%;
    display: block;
  }

  .cds--col-lg-16 {
    flex: 0 0 100%;
    max-inline-size: 100%;
    display: block;
  }

  .cds--offset-lg-0 {
    margin-inline-start: 0;
  }

  .cds--offset-lg-1 {
    margin-inline-start: 6.25%;
  }

  .cds--offset-lg-2 {
    margin-inline-start: 12.5%;
  }

  .cds--offset-lg-3 {
    margin-inline-start: 18.75%;
  }

  .cds--offset-lg-4 {
    margin-inline-start: 25%;
  }

  .cds--offset-lg-5 {
    margin-inline-start: 31.25%;
  }

  .cds--offset-lg-6 {
    margin-inline-start: 37.5%;
  }

  .cds--offset-lg-7 {
    margin-inline-start: 43.75%;
  }

  .cds--offset-lg-8 {
    margin-inline-start: 50%;
  }

  .cds--offset-lg-9 {
    margin-inline-start: 56.25%;
  }

  .cds--offset-lg-10 {
    margin-inline-start: 62.5%;
  }

  .cds--offset-lg-11 {
    margin-inline-start: 68.75%;
  }

  .cds--offset-lg-12 {
    margin-inline-start: 75%;
  }

  .cds--offset-lg-13 {
    margin-inline-start: 81.25%;
  }

  .cds--offset-lg-14 {
    margin-inline-start: 87.5%;
  }

  .cds--offset-lg-15 {
    margin-inline-start: 93.75%;
  }
}

.cds--col-xlg-0 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-0, .cds--grid--condensed .cds--col-xlg-0 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-0, .cds--grid--narrow .cds--col-xlg-0 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-1 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-1, .cds--grid--condensed .cds--col-xlg-1 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-1, .cds--grid--narrow .cds--col-xlg-1 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-2 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-2, .cds--grid--condensed .cds--col-xlg-2 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-2, .cds--grid--narrow .cds--col-xlg-2 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-3 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-3, .cds--grid--condensed .cds--col-xlg-3 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-3, .cds--grid--narrow .cds--col-xlg-3 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-4 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-4, .cds--grid--condensed .cds--col-xlg-4 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-4, .cds--grid--narrow .cds--col-xlg-4 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-5 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-5, .cds--grid--condensed .cds--col-xlg-5 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-5, .cds--grid--narrow .cds--col-xlg-5 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-6 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-6, .cds--grid--condensed .cds--col-xlg-6 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-6, .cds--grid--narrow .cds--col-xlg-6 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-7 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-7, .cds--grid--condensed .cds--col-xlg-7 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-7, .cds--grid--narrow .cds--col-xlg-7 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-8 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-8, .cds--grid--condensed .cds--col-xlg-8 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-8, .cds--grid--narrow .cds--col-xlg-8 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-9 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-9, .cds--grid--condensed .cds--col-xlg-9 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-9, .cds--grid--narrow .cds--col-xlg-9 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-10 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-10, .cds--grid--condensed .cds--col-xlg-10 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-10, .cds--grid--narrow .cds--col-xlg-10 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-11 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-11, .cds--grid--condensed .cds--col-xlg-11 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-11, .cds--grid--narrow .cds--col-xlg-11 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-12 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-12, .cds--grid--condensed .cds--col-xlg-12 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-12, .cds--grid--narrow .cds--col-xlg-12 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-13 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-13, .cds--grid--condensed .cds--col-xlg-13 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-13, .cds--grid--narrow .cds--col-xlg-13 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-14 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-14, .cds--grid--condensed .cds--col-xlg-14 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-14, .cds--grid--narrow .cds--col-xlg-14 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-15 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-15, .cds--grid--condensed .cds--col-xlg-15 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-15, .cds--grid--narrow .cds--col-xlg-15 {
  padding-inline: 0 1rem;
}

.cds--col-xlg-16 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg-16, .cds--grid--condensed .cds--col-xlg-16 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg-16, .cds--grid--narrow .cds--col-xlg-16 {
  padding-inline: 0 1rem;
}

.cds--col-xlg, .cds--col-xlg--auto {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-xlg, .cds--grid--condensed .cds--col-xlg, .cds--row--condensed .cds--col-xlg--auto, .cds--grid--condensed .cds--col-xlg--auto {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-xlg, .cds--grid--narrow .cds--col-xlg, .cds--row--narrow .cds--col-xlg--auto, .cds--grid--narrow .cds--col-xlg--auto {
  padding-inline: 0 1rem;
}

@media (width >= 82rem) {
  .cds--col, .cds--col-xlg {
    flex-grow: 1;
    flex-basis: 0;
    max-inline-size: 100%;
  }

  .cds--col--auto, .cds--col-xlg--auto {
    flex: 1 0;
    inline-size: auto;
    max-inline-size: 100%;
  }

  .cds--col-xlg-0 {
    display: none;
  }

  .cds--col-xlg-1 {
    flex: 0 0 6.25%;
    max-inline-size: 6.25%;
    display: block;
  }

  .cds--col-xlg-2 {
    flex: 0 0 12.5%;
    max-inline-size: 12.5%;
    display: block;
  }

  .cds--col-xlg-3 {
    flex: 0 0 18.75%;
    max-inline-size: 18.75%;
    display: block;
  }

  .cds--col-xlg-4 {
    flex: 0 0 25%;
    max-inline-size: 25%;
    display: block;
  }

  .cds--col-xlg-5 {
    flex: 0 0 31.25%;
    max-inline-size: 31.25%;
    display: block;
  }

  .cds--col-xlg-6 {
    flex: 0 0 37.5%;
    max-inline-size: 37.5%;
    display: block;
  }

  .cds--col-xlg-7 {
    flex: 0 0 43.75%;
    max-inline-size: 43.75%;
    display: block;
  }

  .cds--col-xlg-8 {
    flex: 0 0 50%;
    max-inline-size: 50%;
    display: block;
  }

  .cds--col-xlg-9 {
    flex: 0 0 56.25%;
    max-inline-size: 56.25%;
    display: block;
  }

  .cds--col-xlg-10 {
    flex: 0 0 62.5%;
    max-inline-size: 62.5%;
    display: block;
  }

  .cds--col-xlg-11 {
    flex: 0 0 68.75%;
    max-inline-size: 68.75%;
    display: block;
  }

  .cds--col-xlg-12 {
    flex: 0 0 75%;
    max-inline-size: 75%;
    display: block;
  }

  .cds--col-xlg-13 {
    flex: 0 0 81.25%;
    max-inline-size: 81.25%;
    display: block;
  }

  .cds--col-xlg-14 {
    flex: 0 0 87.5%;
    max-inline-size: 87.5%;
    display: block;
  }

  .cds--col-xlg-15 {
    flex: 0 0 93.75%;
    max-inline-size: 93.75%;
    display: block;
  }

  .cds--col-xlg-16 {
    flex: 0 0 100%;
    max-inline-size: 100%;
    display: block;
  }

  .cds--offset-xlg-0 {
    margin-inline-start: 0;
  }

  .cds--offset-xlg-1 {
    margin-inline-start: 6.25%;
  }

  .cds--offset-xlg-2 {
    margin-inline-start: 12.5%;
  }

  .cds--offset-xlg-3 {
    margin-inline-start: 18.75%;
  }

  .cds--offset-xlg-4 {
    margin-inline-start: 25%;
  }

  .cds--offset-xlg-5 {
    margin-inline-start: 31.25%;
  }

  .cds--offset-xlg-6 {
    margin-inline-start: 37.5%;
  }

  .cds--offset-xlg-7 {
    margin-inline-start: 43.75%;
  }

  .cds--offset-xlg-8 {
    margin-inline-start: 50%;
  }

  .cds--offset-xlg-9 {
    margin-inline-start: 56.25%;
  }

  .cds--offset-xlg-10 {
    margin-inline-start: 62.5%;
  }

  .cds--offset-xlg-11 {
    margin-inline-start: 68.75%;
  }

  .cds--offset-xlg-12 {
    margin-inline-start: 75%;
  }

  .cds--offset-xlg-13 {
    margin-inline-start: 81.25%;
  }

  .cds--offset-xlg-14 {
    margin-inline-start: 87.5%;
  }

  .cds--offset-xlg-15 {
    margin-inline-start: 93.75%;
  }
}

.cds--col-max-0 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-0, .cds--grid--condensed .cds--col-max-0 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-0, .cds--grid--narrow .cds--col-max-0 {
  padding-inline: 0 1rem;
}

.cds--col-max-1 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-1, .cds--grid--condensed .cds--col-max-1 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-1, .cds--grid--narrow .cds--col-max-1 {
  padding-inline: 0 1rem;
}

.cds--col-max-2 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-2, .cds--grid--condensed .cds--col-max-2 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-2, .cds--grid--narrow .cds--col-max-2 {
  padding-inline: 0 1rem;
}

.cds--col-max-3 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-3, .cds--grid--condensed .cds--col-max-3 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-3, .cds--grid--narrow .cds--col-max-3 {
  padding-inline: 0 1rem;
}

.cds--col-max-4 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-4, .cds--grid--condensed .cds--col-max-4 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-4, .cds--grid--narrow .cds--col-max-4 {
  padding-inline: 0 1rem;
}

.cds--col-max-5 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-5, .cds--grid--condensed .cds--col-max-5 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-5, .cds--grid--narrow .cds--col-max-5 {
  padding-inline: 0 1rem;
}

.cds--col-max-6 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-6, .cds--grid--condensed .cds--col-max-6 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-6, .cds--grid--narrow .cds--col-max-6 {
  padding-inline: 0 1rem;
}

.cds--col-max-7 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-7, .cds--grid--condensed .cds--col-max-7 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-7, .cds--grid--narrow .cds--col-max-7 {
  padding-inline: 0 1rem;
}

.cds--col-max-8 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-8, .cds--grid--condensed .cds--col-max-8 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-8, .cds--grid--narrow .cds--col-max-8 {
  padding-inline: 0 1rem;
}

.cds--col-max-9 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-9, .cds--grid--condensed .cds--col-max-9 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-9, .cds--grid--narrow .cds--col-max-9 {
  padding-inline: 0 1rem;
}

.cds--col-max-10 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-10, .cds--grid--condensed .cds--col-max-10 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-10, .cds--grid--narrow .cds--col-max-10 {
  padding-inline: 0 1rem;
}

.cds--col-max-11 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-11, .cds--grid--condensed .cds--col-max-11 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-11, .cds--grid--narrow .cds--col-max-11 {
  padding-inline: 0 1rem;
}

.cds--col-max-12 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-12, .cds--grid--condensed .cds--col-max-12 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-12, .cds--grid--narrow .cds--col-max-12 {
  padding-inline: 0 1rem;
}

.cds--col-max-13 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-13, .cds--grid--condensed .cds--col-max-13 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-13, .cds--grid--narrow .cds--col-max-13 {
  padding-inline: 0 1rem;
}

.cds--col-max-14 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-14, .cds--grid--condensed .cds--col-max-14 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-14, .cds--grid--narrow .cds--col-max-14 {
  padding-inline: 0 1rem;
}

.cds--col-max-15 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-15, .cds--grid--condensed .cds--col-max-15 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-15, .cds--grid--narrow .cds--col-max-15 {
  padding-inline: 0 1rem;
}

.cds--col-max-16 {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max-16, .cds--grid--condensed .cds--col-max-16 {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max-16, .cds--grid--narrow .cds--col-max-16 {
  padding-inline: 0 1rem;
}

.cds--col-max, .cds--col-max--auto {
  inline-size: 100%;
  padding-inline: 1rem;
}

.cds--row--condensed .cds--col-max, .cds--grid--condensed .cds--col-max, .cds--row--condensed .cds--col-max--auto, .cds--grid--condensed .cds--col-max--auto {
  padding-inline: .03125rem;
}

.cds--row--narrow .cds--col-max, .cds--grid--narrow .cds--col-max, .cds--row--narrow .cds--col-max--auto, .cds--grid--narrow .cds--col-max--auto {
  padding-inline: 0 1rem;
}

@media (width >= 99rem) {
  .cds--col, .cds--col-max {
    flex-grow: 1;
    flex-basis: 0;
    max-inline-size: 100%;
  }

  .cds--col--auto, .cds--col-max--auto {
    flex: 1 0;
    inline-size: auto;
    max-inline-size: 100%;
  }

  .cds--col-max-0 {
    display: none;
  }

  .cds--col-max-1 {
    flex: 0 0 6.25%;
    max-inline-size: 6.25%;
    display: block;
  }

  .cds--col-max-2 {
    flex: 0 0 12.5%;
    max-inline-size: 12.5%;
    display: block;
  }

  .cds--col-max-3 {
    flex: 0 0 18.75%;
    max-inline-size: 18.75%;
    display: block;
  }

  .cds--col-max-4 {
    flex: 0 0 25%;
    max-inline-size: 25%;
    display: block;
  }

  .cds--col-max-5 {
    flex: 0 0 31.25%;
    max-inline-size: 31.25%;
    display: block;
  }

  .cds--col-max-6 {
    flex: 0 0 37.5%;
    max-inline-size: 37.5%;
    display: block;
  }

  .cds--col-max-7 {
    flex: 0 0 43.75%;
    max-inline-size: 43.75%;
    display: block;
  }

  .cds--col-max-8 {
    flex: 0 0 50%;
    max-inline-size: 50%;
    display: block;
  }

  .cds--col-max-9 {
    flex: 0 0 56.25%;
    max-inline-size: 56.25%;
    display: block;
  }

  .cds--col-max-10 {
    flex: 0 0 62.5%;
    max-inline-size: 62.5%;
    display: block;
  }

  .cds--col-max-11 {
    flex: 0 0 68.75%;
    max-inline-size: 68.75%;
    display: block;
  }

  .cds--col-max-12 {
    flex: 0 0 75%;
    max-inline-size: 75%;
    display: block;
  }

  .cds--col-max-13 {
    flex: 0 0 81.25%;
    max-inline-size: 81.25%;
    display: block;
  }

  .cds--col-max-14 {
    flex: 0 0 87.5%;
    max-inline-size: 87.5%;
    display: block;
  }

  .cds--col-max-15 {
    flex: 0 0 93.75%;
    max-inline-size: 93.75%;
    display: block;
  }

  .cds--col-max-16 {
    flex: 0 0 100%;
    max-inline-size: 100%;
    display: block;
  }

  .cds--offset-max-0 {
    margin-inline-start: 0;
  }

  .cds--offset-max-1 {
    margin-inline-start: 6.25%;
  }

  .cds--offset-max-2 {
    margin-inline-start: 12.5%;
  }

  .cds--offset-max-3 {
    margin-inline-start: 18.75%;
  }

  .cds--offset-max-4 {
    margin-inline-start: 25%;
  }

  .cds--offset-max-5 {
    margin-inline-start: 31.25%;
  }

  .cds--offset-max-6 {
    margin-inline-start: 37.5%;
  }

  .cds--offset-max-7 {
    margin-inline-start: 43.75%;
  }

  .cds--offset-max-8 {
    margin-inline-start: 50%;
  }

  .cds--offset-max-9 {
    margin-inline-start: 56.25%;
  }

  .cds--offset-max-10 {
    margin-inline-start: 62.5%;
  }

  .cds--offset-max-11 {
    margin-inline-start: 68.75%;
  }

  .cds--offset-max-12 {
    margin-inline-start: 75%;
  }

  .cds--offset-max-13 {
    margin-inline-start: 81.25%;
  }

  .cds--offset-max-14 {
    margin-inline-start: 87.5%;
  }

  .cds--offset-max-15 {
    margin-inline-start: 93.75%;
  }
}

.cds--no-gutter, .cds--row.cds--no-gutter [class*="cds--col"] {
  padding-inline: 0;
}

.cds--no-gutter--start, .cds--row.cds--no-gutter--start [class*="cds--col"] {
  padding-inline-start: 0;
}

.cds--no-gutter--end, .cds--row.cds--no-gutter--end [class*="cds--col"] {
  padding-inline-end: 0;
}

.cds--hang--start {
  padding-inline-start: 1rem;
}

.cds--hang--end {
  padding-inline-end: 1rem;
}

html {
  font-size: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: IBM Plex Sans, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, sans-serif;
  font-weight: 400;
}

code {
  font-family: IBM Plex Mono, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, monospace;
}

strong {
  font-weight: 600;
}

h1 {
  font-size: var(--cds-heading-06-font-size, 2.625rem);
  font-weight: var(--cds-heading-06-font-weight, 300);
  line-height: var(--cds-heading-06-line-height, 1.199);
  letter-spacing: var(--cds-heading-06-letter-spacing, 0);
}

h2 {
  font-size: var(--cds-heading-05-font-size, 2rem);
  font-weight: var(--cds-heading-05-font-weight, 400);
  line-height: var(--cds-heading-05-line-height, 1.25);
  letter-spacing: var(--cds-heading-05-letter-spacing, 0);
}

h3 {
  font-size: var(--cds-heading-04-font-size, 1.75rem);
  font-weight: var(--cds-heading-04-font-weight, 400);
  line-height: var(--cds-heading-04-line-height, 1.28572);
  letter-spacing: var(--cds-heading-04-letter-spacing, 0);
}

h4 {
  font-size: var(--cds-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-heading-03-font-weight, 400);
  line-height: var(--cds-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-heading-03-letter-spacing, 0);
}

h5 {
  font-size: var(--cds-heading-02-font-size, 1rem);
  font-weight: var(--cds-heading-02-font-weight, 600);
  line-height: var(--cds-heading-02-line-height, 1.5);
  letter-spacing: var(--cds-heading-02-letter-spacing, 0);
}

h6 {
  font-size: var(--cds-heading-01-font-size, .875rem);
  font-weight: var(--cds-heading-01-font-weight, 600);
  line-height: var(--cds-heading-01-line-height, 1.42857);
  letter-spacing: var(--cds-heading-01-letter-spacing, .16px);
}

p {
  font-size: var(--cds-body-02-font-size, 1rem);
  font-weight: var(--cds-body-02-font-weight, 400);
  line-height: var(--cds-body-02-line-height, 1.5);
  letter-spacing: var(--cds-body-02-letter-spacing, 0);
}

a {
  color: var(--cds-link-primary, #0062fe);
}

em {
  font-style: italic;
}

.cds--type-mono {
  font-family: IBM Plex Mono, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, monospace;
}

.cds--type-sans {
  font-family: IBM Plex Sans, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, sans-serif;
}

.cds--type-sans-condensed {
  font-family: IBM Plex Sans Condensed, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, sans-serif;
}

.cds--type-sans-arabic {
  font-family: IBM Plex Sans Arabic, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, sans-serif;
}

.cds--type-sans-devanagari {
  font-family: IBM Plex Sans Devanagari, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, sans-serif;
}

.cds--type-sans-hebrew {
  font-family: IBM Plex Sans Hebrew, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, sans-serif;
}

.cds--type-sans-jp {
  font-family: IBM Plex Sans JP, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, sans-serif;
}

.cds--type-sans-kr {
  font-family: IBM Plex Sans KR, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, sans-serif;
}

.cds--type-sans-thai-looped {
  font-family: IBM Plex Sans Thai Looped, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, sans-serif;
}

.cds--type-sans-thai {
  font-family: IBM Plex Sans Thai, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, sans-serif;
}

.cds--type-serif {
  font-family: IBM Plex Serif, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, serif;
}

.cds--type-light {
  font-weight: 300;
}

.cds--type-regular {
  font-weight: 400;
}

.cds--type-semibold {
  font-weight: 600;
}

.cds--type-italic {
  font-style: italic;
}

.cds--type-caption-01 {
  font-size: var(--cds-caption-01-font-size, .75rem);
  font-weight: var(--cds-caption-01-font-weight, 400);
  line-height: var(--cds-caption-01-line-height, 1.33333);
  letter-spacing: var(--cds-caption-01-letter-spacing, .32px);
}

.cds--type-caption-02 {
  font-size: var(--cds-caption-02-font-size, .875rem);
  font-weight: var(--cds-caption-02-font-weight, 400);
  line-height: var(--cds-caption-02-line-height, 1.28572);
  letter-spacing: var(--cds-caption-02-letter-spacing, .32px);
}

.cds--type-label-01 {
  font-size: var(--cds-label-01-font-size, .75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, .32px);
}

.cds--type-label-02 {
  font-size: var(--cds-label-02-font-size, .875rem);
  font-weight: var(--cds-label-02-font-weight, 400);
  line-height: var(--cds-label-02-line-height, 1.28572);
  letter-spacing: var(--cds-label-02-letter-spacing, .16px);
}

.cds--type-helper-text-01 {
  font-size: var(--cds-helper-text-01-font-size, .75rem);
  line-height: var(--cds-helper-text-01-line-height, 1.33333);
  letter-spacing: var(--cds-helper-text-01-letter-spacing, .32px);
}

.cds--type-helper-text-02 {
  font-size: var(--cds-helper-text-02-font-size, .875rem);
  font-weight: var(--cds-helper-text-02-font-weight, 400);
  line-height: var(--cds-helper-text-02-line-height, 1.28572);
  letter-spacing: var(--cds-helper-text-02-letter-spacing, .16px);
}

.cds--type-body-short-01 {
  font-size: var(--cds-body-short-01-font-size, .875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, .16px);
}

.cds--type-body-short-02 {
  font-size: var(--cds-body-short-02-font-size, 1rem);
  font-weight: var(--cds-body-short-02-font-weight, 400);
  line-height: var(--cds-body-short-02-line-height, 1.375);
  letter-spacing: var(--cds-body-short-02-letter-spacing, 0);
}

.cds--type-body-long-01 {
  font-size: var(--cds-body-long-01-font-size, .875rem);
  font-weight: var(--cds-body-long-01-font-weight, 400);
  line-height: var(--cds-body-long-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-long-01-letter-spacing, .16px);
}

.cds--type-body-long-02 {
  font-size: var(--cds-body-long-02-font-size, 1rem);
  font-weight: var(--cds-body-long-02-font-weight, 400);
  line-height: var(--cds-body-long-02-line-height, 1.5);
  letter-spacing: var(--cds-body-long-02-letter-spacing, 0);
}

.cds--type-code-01 {
  font-family: var(--cds-code-01-font-family, "IBM Plex Mono", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", monospace);
  font-size: var(--cds-code-01-font-size, .75rem);
  font-weight: var(--cds-code-01-font-weight, 400);
  line-height: var(--cds-code-01-line-height, 1.33333);
  letter-spacing: var(--cds-code-01-letter-spacing, .32px);
}

.cds--type-code-02 {
  font-family: var(--cds-code-02-font-family, "IBM Plex Mono", system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", monospace);
  font-size: var(--cds-code-02-font-size, .875rem);
  font-weight: var(--cds-code-02-font-weight, 400);
  line-height: var(--cds-code-02-line-height, 1.42857);
  letter-spacing: var(--cds-code-02-letter-spacing, .32px);
}

.cds--type-heading-01 {
  font-size: var(--cds-heading-01-font-size, .875rem);
  font-weight: var(--cds-heading-01-font-weight, 600);
  line-height: var(--cds-heading-01-line-height, 1.42857);
  letter-spacing: var(--cds-heading-01-letter-spacing, .16px);
}

.cds--type-heading-02 {
  font-size: var(--cds-heading-02-font-size, 1rem);
  font-weight: var(--cds-heading-02-font-weight, 600);
  line-height: var(--cds-heading-02-line-height, 1.5);
  letter-spacing: var(--cds-heading-02-letter-spacing, 0);
}

.cds--type-productive-heading-01 {
  font-size: var(--cds-productive-heading-01-font-size, .875rem);
  font-weight: var(--cds-productive-heading-01-font-weight, 600);
  line-height: var(--cds-productive-heading-01-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-01-letter-spacing, .16px);
}

.cds--type-productive-heading-02 {
  font-size: var(--cds-productive-heading-02-font-size, 1rem);
  font-weight: var(--cds-productive-heading-02-font-weight, 600);
  line-height: var(--cds-productive-heading-02-line-height, 1.375);
  letter-spacing: var(--cds-productive-heading-02-letter-spacing, 0);
}

.cds--type-productive-heading-03 {
  font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-productive-heading-03-font-weight, 400);
  line-height: var(--cds-productive-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0);
}

.cds--type-productive-heading-04 {
  font-size: var(--cds-productive-heading-04-font-size, 1.75rem);
  font-weight: var(--cds-productive-heading-04-font-weight, 400);
  line-height: var(--cds-productive-heading-04-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-04-letter-spacing, 0);
}

.cds--type-productive-heading-05 {
  font-size: var(--cds-productive-heading-05-font-size, 2rem);
  font-weight: var(--cds-productive-heading-05-font-weight, 400);
  line-height: var(--cds-productive-heading-05-line-height, 1.25);
  letter-spacing: var(--cds-productive-heading-05-letter-spacing, 0);
}

.cds--type-productive-heading-06 {
  font-size: var(--cds-productive-heading-06-font-size, 2.625rem);
  font-weight: var(--cds-productive-heading-06-font-weight, 300);
  line-height: var(--cds-productive-heading-06-line-height, 1.199);
  letter-spacing: var(--cds-productive-heading-06-letter-spacing, 0);
}

.cds--type-productive-heading-07 {
  font-size: var(--cds-productive-heading-07-font-size, 3.375rem);
  font-weight: var(--cds-productive-heading-07-font-weight, 300);
  line-height: var(--cds-productive-heading-07-line-height, 1.19);
  letter-spacing: var(--cds-productive-heading-07-letter-spacing, 0);
}

.cds--type-expressive-paragraph-01 {
  letter-spacing: 0;
  font-size: calc(.543478vw + 1.3913rem);
  font-weight: 300;
  line-height: 1.334;
}

@media (width >= 66rem) {
  .cds--type-expressive-paragraph-01 {
    font-size: calc(.757576vw + 1.25rem);
    line-height: 1.28572;
  }
}

@media (width >= 99rem) {
  .cds--type-expressive-paragraph-01 {
    font-size: 2rem;
    line-height: 1.25;
  }
}

.cds--type-expressive-heading-01 {
  font-size: var(--cds-expressive-heading-01-font-size, .875rem);
  font-weight: var(--cds-expressive-heading-01-font-weight, 600);
  line-height: var(--cds-expressive-heading-01-line-height, 1.42857);
  letter-spacing: var(--cds-expressive-heading-01-letter-spacing, .16px);
}

.cds--type-expressive-heading-02 {
  font-size: var(--cds-expressive-heading-02-font-size, 1rem);
  font-weight: var(--cds-expressive-heading-02-font-weight, 600);
  line-height: var(--cds-expressive-heading-02-line-height, 1.5);
  letter-spacing: var(--cds-expressive-heading-02-letter-spacing, 0);
}

.cds--type-expressive-heading-03 {
  letter-spacing: 0;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
}

@media (width >= 82rem) {
  .cds--type-expressive-heading-03 {
    font-size: calc(1.47059vw + .0441177rem);
    line-height: 1.4;
  }
}

@media (width >= 99rem) {
  .cds--type-expressive-heading-03 {
    font-size: 1.5rem;
    line-height: 1.334;
  }
}

.cds--type-expressive-heading-04 {
  letter-spacing: 0;
  font-size: calc(.403226vw + 1.66935rem);
  font-weight: 400;
  line-height: 1.28572;
}

@media (width >= 82rem) {
  .cds--type-expressive-heading-04 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.25;
  }
}

@media (width >= 99rem) {
  .cds--type-expressive-heading-04 {
    font-size: 2rem;
    font-weight: 400;
  }
}

.cds--type-expressive-heading-05 {
  letter-spacing: 0;
  font-size: calc(1.13636vw + 1.77273rem);
  font-weight: 400;
  line-height: 1.25;
}

@media (width >= 42rem) {
  .cds--type-expressive-heading-05 {
    font-size: calc(1.5625vw + 1.59375rem);
    font-weight: 300;
    line-height: 1.22;
  }
}

@media (width >= 66rem) {
  .cds--type-expressive-heading-05 {
    font-size: calc(2.34375vw + 1.07813rem);
    line-height: 1.19;
  }
}

@media (width >= 82rem) {
  .cds--type-expressive-heading-05 {
    font-size: calc(4.41176vw - .617647rem);
    line-height: 1.17;
  }
}

@media (width >= 99rem) {
  .cds--type-expressive-heading-05 {
    font-size: 3.75rem;
  }
}

.cds--type-expressive-heading-06 {
  letter-spacing: 0;
  font-size: calc(1.13636vw + 1.77273rem);
  font-weight: 600;
  line-height: 1.25;
}

@media (width >= 42rem) {
  .cds--type-expressive-heading-06 {
    font-size: calc(1.5625vw + 1.59375rem);
    line-height: 1.22;
  }
}

@media (width >= 66rem) {
  .cds--type-expressive-heading-06 {
    font-size: calc(2.34375vw + 1.07813rem);
    line-height: 1.19;
  }
}

@media (width >= 82rem) {
  .cds--type-expressive-heading-06 {
    font-size: calc(4.41176vw - .617647rem);
    line-height: 1.17;
  }
}

@media (width >= 99rem) {
  .cds--type-expressive-heading-06 {
    font-size: 3.75rem;
  }
}

.cds--type-quotation-01 {
  letter-spacing: 0;
  font-family: IBM Plex Serif, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.3;
}

@media (width >= 42rem) {
  .cds--type-quotation-01 {
    font-size: calc(1.04167vw + .8125rem);
  }
}

@media (width >= 66rem) {
  .cds--type-quotation-01 {
    font-size: calc(1.5625vw + .46875rem);
    line-height: 1.334;
  }
}

@media (width >= 82rem) {
  .cds--type-quotation-01 {
    font-size: calc(1.47059vw + .544118rem);
    line-height: 1.28572;
  }
}

@media (width >= 99rem) {
  .cds--type-quotation-01 {
    font-size: 2rem;
    line-height: 1.25;
  }
}

.cds--type-quotation-02 {
  letter-spacing: 0;
  font-family: IBM Plex Serif, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, serif;
  font-size: calc(1.13636vw + 1.77273rem);
  font-weight: 300;
  line-height: 1.25;
}

@media (width >= 42rem) {
  .cds--type-quotation-02 {
    font-size: calc(1.5625vw + 1.59375rem);
    line-height: 1.22;
  }
}

@media (width >= 66rem) {
  .cds--type-quotation-02 {
    font-size: calc(2.34375vw + 1.07813rem);
    line-height: 1.19;
  }
}

@media (width >= 82rem) {
  .cds--type-quotation-02 {
    font-size: calc(4.41176vw - .617647rem);
    line-height: 1.17;
  }
}

@media (width >= 99rem) {
  .cds--type-quotation-02 {
    font-size: 3.75rem;
  }
}

.cds--type-display-01 {
  letter-spacing: 0;
  font-size: 2.625rem;
  font-weight: 300;
  line-height: 1.19;
}

@media (width >= 42rem) {
  .cds--type-display-01 {
    font-size: calc(3.125vw + 1.3125rem);
  }
}

@media (width >= 66rem) {
  .cds--type-display-01 {
    font-size: calc(2.34375vw + 1.82813rem);
  }
}

@media (width >= 82rem) {
  .cds--type-display-01 {
    font-size: calc(5.88235vw - 1.07353rem);
    line-height: 1.17;
  }
}

@media (width >= 99rem) {
  .cds--type-display-01 {
    font-size: 4.75rem;
    line-height: 1.13;
  }
}

.cds--type-display-02 {
  letter-spacing: 0;
  font-size: 2.625rem;
  font-weight: 600;
  line-height: 1.19;
}

@media (width >= 42rem) {
  .cds--type-display-02 {
    font-size: calc(3.125vw + 1.3125rem);
  }
}

@media (width >= 66rem) {
  .cds--type-display-02 {
    font-size: calc(2.34375vw + 1.82813rem);
  }
}

@media (width >= 82rem) {
  .cds--type-display-02 {
    font-size: calc(5.88235vw - 1.07353rem);
    line-height: 1.16;
  }
}

@media (width >= 99rem) {
  .cds--type-display-02 {
    font-size: 4.75rem;
    line-height: 1.13;
  }
}

.cds--type-display-03 {
  letter-spacing: 0;
  font-size: calc(3.40909vw + 1.94318rem);
  font-weight: 300;
  line-height: 1.19;
}

@media (width >= 42rem) {
  .cds--type-display-03 {
    font-size: calc(1.5625vw + 2.71875rem);
    line-height: 1.18;
  }
}

@media (width >= 66rem) {
  .cds--type-display-03 {
    letter-spacing: -.64px;
    font-size: calc(6.25vw - .375rem);
    line-height: 1.16;
  }
}

@media (width >= 82rem) {
  .cds--type-display-03 {
    letter-spacing: -.64px;
    font-size: calc(2.94118vw + 2.33824rem);
    line-height: 1.13;
  }
}

@media (width >= 99rem) {
  .cds--type-display-03 {
    letter-spacing: -.96px;
    font-size: 5.25rem;
    line-height: 1.11;
  }
}

.cds--type-display-04 {
  letter-spacing: 0;
  font-size: calc(7.38636vw + 1.14773rem);
  font-weight: 300;
  line-height: 1.19;
}

@media (width >= 42rem) {
  .cds--type-display-04 {
    font-size: calc(6.25vw + 1.625rem);
    line-height: 1.15;
  }
}

@media (width >= 66rem) {
  .cds--type-display-04 {
    letter-spacing: -.64px;
    font-size: calc(11.7188vw - 1.98438rem);
    line-height: 1.11;
  }
}

@media (width >= 82rem) {
  .cds--type-display-04 {
    letter-spacing: -.64px;
    font-size: calc(12.5vw - 2.625rem);
    line-height: 1.07;
  }
}

@media (width >= 99rem) {
  .cds--type-display-04 {
    letter-spacing: -.96px;
    font-size: 9.75rem;
    line-height: 1.05;
  }
}

.cds--type-legal-01 {
  font-size: var(--cds-legal-01-font-size, .75rem);
  font-weight: var(--cds-legal-01-font-weight, 400);
  line-height: var(--cds-legal-01-line-height, 1.33333);
  letter-spacing: var(--cds-legal-01-letter-spacing, .32px);
}

.cds--type-legal-02 {
  font-size: var(--cds-legal-02-font-size, .875rem);
  font-weight: var(--cds-legal-02-font-weight, 400);
  line-height: var(--cds-legal-02-line-height, 1.28572);
  letter-spacing: var(--cds-legal-02-letter-spacing, .16px);
}

.cds--type-body-compact-01 {
  font-size: var(--cds-body-compact-01-font-size, .875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, .16px);
}

.cds--type-body-compact-02 {
  font-size: var(--cds-body-compact-02-font-size, 1rem);
  font-weight: var(--cds-body-compact-02-font-weight, 400);
  line-height: var(--cds-body-compact-02-line-height, 1.375);
  letter-spacing: var(--cds-body-compact-02-letter-spacing, 0);
}

.cds--type-heading-compact-01 {
  font-size: var(--cds-heading-compact-01-font-size, .875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, .16px);
}

.cds--type-heading-compact-02 {
  font-size: var(--cds-heading-compact-02-font-size, 1rem);
  font-weight: var(--cds-heading-compact-02-font-weight, 600);
  line-height: var(--cds-heading-compact-02-line-height, 1.375);
  letter-spacing: var(--cds-heading-compact-02-letter-spacing, 0);
}

.cds--type-body-01 {
  font-size: var(--cds-body-01-font-size, .875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, .16px);
}

.cds--type-body-02 {
  font-size: var(--cds-body-02-font-size, 1rem);
  font-weight: var(--cds-body-02-font-weight, 400);
  line-height: var(--cds-body-02-line-height, 1.5);
  letter-spacing: var(--cds-body-02-letter-spacing, 0);
}

.cds--type-heading-03 {
  font-size: var(--cds-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-heading-03-font-weight, 400);
  line-height: var(--cds-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-heading-03-letter-spacing, 0);
}

.cds--type-heading-04 {
  font-size: var(--cds-heading-04-font-size, 1.75rem);
  font-weight: var(--cds-heading-04-font-weight, 400);
  line-height: var(--cds-heading-04-line-height, 1.28572);
  letter-spacing: var(--cds-heading-04-letter-spacing, 0);
}

.cds--type-heading-05 {
  font-size: var(--cds-heading-05-font-size, 2rem);
  font-weight: var(--cds-heading-05-font-weight, 400);
  line-height: var(--cds-heading-05-line-height, 1.25);
  letter-spacing: var(--cds-heading-05-letter-spacing, 0);
}

.cds--type-heading-06 {
  font-size: var(--cds-heading-06-font-size, 2.625rem);
  font-weight: var(--cds-heading-06-font-weight, 300);
  line-height: var(--cds-heading-06-line-height, 1.199);
  letter-spacing: var(--cds-heading-06-letter-spacing, 0);
}

.cds--type-heading-07 {
  font-size: var(--cds-heading-07-font-size, 3.375rem);
  font-weight: var(--cds-heading-07-font-weight, 300);
  line-height: var(--cds-heading-07-line-height, 1.19);
  letter-spacing: var(--cds-heading-07-letter-spacing, 0);
}

.cds--type-fluid-heading-03 {
  letter-spacing: 0;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
}

@media (width >= 82rem) {
  .cds--type-fluid-heading-03 {
    font-size: calc(1.47059vw + .0441177rem);
    line-height: 1.4;
  }
}

@media (width >= 99rem) {
  .cds--type-fluid-heading-03 {
    font-size: 1.5rem;
    line-height: 1.334;
  }
}

.cds--type-fluid-heading-04 {
  letter-spacing: 0;
  font-size: calc(.403226vw + 1.66935rem);
  font-weight: 400;
  line-height: 1.28572;
}

@media (width >= 82rem) {
  .cds--type-fluid-heading-04 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.25;
  }
}

@media (width >= 99rem) {
  .cds--type-fluid-heading-04 {
    font-size: 2rem;
    font-weight: 400;
  }
}

.cds--type-fluid-heading-05 {
  letter-spacing: 0;
  font-size: calc(1.13636vw + 1.77273rem);
  font-weight: 400;
  line-height: 1.25;
}

@media (width >= 42rem) {
  .cds--type-fluid-heading-05 {
    font-size: calc(1.5625vw + 1.59375rem);
    font-weight: 300;
    line-height: 1.22;
  }
}

@media (width >= 66rem) {
  .cds--type-fluid-heading-05 {
    font-size: calc(2.34375vw + 1.07813rem);
    line-height: 1.19;
  }
}

@media (width >= 82rem) {
  .cds--type-fluid-heading-05 {
    font-size: calc(4.41176vw - .617647rem);
    line-height: 1.17;
  }
}

@media (width >= 99rem) {
  .cds--type-fluid-heading-05 {
    font-size: 3.75rem;
  }
}

.cds--type-fluid-heading-06 {
  letter-spacing: 0;
  font-size: calc(1.13636vw + 1.77273rem);
  font-weight: 600;
  line-height: 1.25;
}

@media (width >= 42rem) {
  .cds--type-fluid-heading-06 {
    font-size: calc(1.5625vw + 1.59375rem);
    line-height: 1.22;
  }
}

@media (width >= 66rem) {
  .cds--type-fluid-heading-06 {
    font-size: calc(2.34375vw + 1.07813rem);
    line-height: 1.19;
  }
}

@media (width >= 82rem) {
  .cds--type-fluid-heading-06 {
    font-size: calc(4.41176vw - .617647rem);
    line-height: 1.17;
  }
}

@media (width >= 99rem) {
  .cds--type-fluid-heading-06 {
    font-size: 3.75rem;
  }
}

.cds--type-fluid-paragraph-01 {
  letter-spacing: 0;
  font-size: calc(.543478vw + 1.3913rem);
  font-weight: 300;
  line-height: 1.334;
}

@media (width >= 66rem) {
  .cds--type-fluid-paragraph-01 {
    font-size: calc(.757576vw + 1.25rem);
    line-height: 1.28572;
  }
}

@media (width >= 99rem) {
  .cds--type-fluid-paragraph-01 {
    font-size: 2rem;
    line-height: 1.25;
  }
}

.cds--type-fluid-quotation-01 {
  letter-spacing: 0;
  font-family: IBM Plex Serif, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.3;
}

@media (width >= 42rem) {
  .cds--type-fluid-quotation-01 {
    font-size: calc(1.04167vw + .8125rem);
  }
}

@media (width >= 66rem) {
  .cds--type-fluid-quotation-01 {
    font-size: calc(1.5625vw + .46875rem);
    line-height: 1.334;
  }
}

@media (width >= 82rem) {
  .cds--type-fluid-quotation-01 {
    font-size: calc(1.47059vw + .544118rem);
    line-height: 1.28572;
  }
}

@media (width >= 99rem) {
  .cds--type-fluid-quotation-01 {
    font-size: 2rem;
    line-height: 1.25;
  }
}

.cds--type-fluid-quotation-02 {
  letter-spacing: 0;
  font-family: IBM Plex Serif, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, serif;
  font-size: calc(1.13636vw + 1.77273rem);
  font-weight: 300;
  line-height: 1.25;
}

@media (width >= 42rem) {
  .cds--type-fluid-quotation-02 {
    font-size: calc(1.5625vw + 1.59375rem);
    line-height: 1.22;
  }
}

@media (width >= 66rem) {
  .cds--type-fluid-quotation-02 {
    font-size: calc(2.34375vw + 1.07813rem);
    line-height: 1.19;
  }
}

@media (width >= 82rem) {
  .cds--type-fluid-quotation-02 {
    font-size: calc(4.41176vw - .617647rem);
    line-height: 1.17;
  }
}

@media (width >= 99rem) {
  .cds--type-fluid-quotation-02 {
    font-size: 3.75rem;
  }
}

.cds--type-fluid-display-01 {
  letter-spacing: 0;
  font-size: 2.625rem;
  font-weight: 300;
  line-height: 1.19;
}

@media (width >= 42rem) {
  .cds--type-fluid-display-01 {
    font-size: calc(3.125vw + 1.3125rem);
  }
}

@media (width >= 66rem) {
  .cds--type-fluid-display-01 {
    font-size: calc(2.34375vw + 1.82813rem);
  }
}

@media (width >= 82rem) {
  .cds--type-fluid-display-01 {
    font-size: calc(5.88235vw - 1.07353rem);
    line-height: 1.17;
  }
}

@media (width >= 99rem) {
  .cds--type-fluid-display-01 {
    font-size: 4.75rem;
    line-height: 1.13;
  }
}

.cds--type-fluid-display-02 {
  letter-spacing: 0;
  font-size: 2.625rem;
  font-weight: 600;
  line-height: 1.19;
}

@media (width >= 42rem) {
  .cds--type-fluid-display-02 {
    font-size: calc(3.125vw + 1.3125rem);
  }
}

@media (width >= 66rem) {
  .cds--type-fluid-display-02 {
    font-size: calc(2.34375vw + 1.82813rem);
  }
}

@media (width >= 82rem) {
  .cds--type-fluid-display-02 {
    font-size: calc(5.88235vw - 1.07353rem);
    line-height: 1.16;
  }
}

@media (width >= 99rem) {
  .cds--type-fluid-display-02 {
    font-size: 4.75rem;
    line-height: 1.13;
  }
}

.cds--type-fluid-display-03 {
  letter-spacing: 0;
  font-size: calc(3.40909vw + 1.94318rem);
  font-weight: 300;
  line-height: 1.19;
}

@media (width >= 42rem) {
  .cds--type-fluid-display-03 {
    font-size: calc(1.5625vw + 2.71875rem);
    line-height: 1.18;
  }
}

@media (width >= 66rem) {
  .cds--type-fluid-display-03 {
    letter-spacing: -.64px;
    font-size: calc(6.25vw - .375rem);
    line-height: 1.16;
  }
}

@media (width >= 82rem) {
  .cds--type-fluid-display-03 {
    letter-spacing: -.64px;
    font-size: calc(2.94118vw + 2.33824rem);
    line-height: 1.13;
  }
}

@media (width >= 99rem) {
  .cds--type-fluid-display-03 {
    letter-spacing: -.96px;
    font-size: 5.25rem;
    line-height: 1.11;
  }
}

.cds--type-fluid-display-04 {
  letter-spacing: 0;
  font-size: calc(7.38636vw + 1.14773rem);
  font-weight: 300;
  line-height: 1.19;
}

@media (width >= 42rem) {
  .cds--type-fluid-display-04 {
    font-size: calc(6.25vw + 1.625rem);
    line-height: 1.15;
  }
}

@media (width >= 66rem) {
  .cds--type-fluid-display-04 {
    letter-spacing: -.64px;
    font-size: calc(11.7188vw - 1.98438rem);
    line-height: 1.11;
  }
}

@media (width >= 82rem) {
  .cds--type-fluid-display-04 {
    letter-spacing: -.64px;
    font-size: calc(12.5vw - 2.625rem);
    line-height: 1.07;
  }
}

@media (width >= 99rem) {
  .cds--type-fluid-display-04 {
    letter-spacing: -.96px;
    font-size: 9.75rem;
    line-height: 1.05;
  }
}

* {
  box-sizing: border-box !important;
}
/*# sourceMappingURL=plex.css.map */
