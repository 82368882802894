@use 'sass:string';
@use '@carbon/styles/scss/config' with (
  $css--default-type: true,
  $css--reset: true
);

@use '@carbon/themes/scss/themes' as themesVars;
@use '@carbon/themes' with (
  $fallback: themesVars.$g10
);

@use "@carbon/styles/scss/reset";

@use '@carbon/type';
@use '@carbon/grid';


@use '@carbon/styles/scss/breakpoint';


@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
html, .cds-theme-zone-white {
  @include themes.theme(themesVars.$white);
  color: var(--cds-text-primary, #161616);
  background: var(--cds-background, white);
}
.cds-theme-zone-g10 {
  @include themes.theme(themesVars.$g10);
  color: var(--cds-text-primary, #161616);
  background: var(--cds-background, white);
}
.cds-theme-zone-g90 {
  @include themes.theme(themesVars.$g90);
  color: var(--cds-text-primary, #161616);
  background: var(--cds-background, white);
}
.cds-theme-zone-g100 {
  @include themes.theme(themesVars.$g100);
  color: var(--cds-text-primary, #161616);
  background: var(--cds-background, white);
}

// Reset
@include reset.reset();

// Grid
@include grid.flex-grid();

@include type.reset();
@include type.default-type();
@include type.type-classes();

* {
  box-sizing: border-box!important;
}
